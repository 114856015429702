import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchHierarchy, getPublication, getTitleDocumentId } from '../../AppStore/Slices/PublicationSlice';

import SplitPane from 'react-split-pane';

import PublicationContent from './PublicationContent';
import PublicationHierarchy from './PublicationHierarchy';
import SideMenu from './SideMenu';
import { getLibraryBook } from '../../AppStore/Slices/LibrarySlice';

const PublicationPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { sourceId, documentId } = useParams<{ sourceId: string, documentId: string }>();

    const libraryBook = useSelector(getLibraryBook(sourceId));
    const publication = useSelector(getPublication(sourceId));
    const titleDocumentId = useSelector(getTitleDocumentId(sourceId));

    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [size, setSize] = useState<number>(500);

    const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

    useEffect(() => {
        const loadPublication = async () => {
            if (libraryBook && (!publication || publication.sourceId !== sourceId)) {
                setLoading(true);
                await dispatch(fetchHierarchy(sourceId));

                setLoading(false);
            }

            const storedSize = localStorage.getItem('splitter-size');
            if (storedSize) {
                setSize(parseInt(storedSize, 10));
            }
    };

        loadPublication();
    }, [dispatch, documentId, libraryBook, publication, sourceId, titleDocumentId]);

    useEffect(() => {
        if (titleDocumentId && !documentId) {
            history.replace(`/publication/${sourceId}/${titleDocumentId}`);
        }
    }, [documentId, history, sourceId, titleDocumentId]);

    const onDragFinished = (newSize: number) => {
        localStorage.setItem('splitter-size', newSize.toString());
    }

    const toggleSideMenuOpen = () => {
        setSideMenuOpen(!sideMenuOpen);
    }

    return (
        <div className="card-wrapper flex-down flex-me full-height">
            {loading ? <div>Loading...</div> :
                isMobile ? (<div className="full-height">
                    <SideMenu isOpen={sideMenuOpen} toggleSideMenuOpen={toggleSideMenuOpen}>
                        <PublicationHierarchy />
                    </SideMenu>
                    <PublicationContent handleOpenSideMenu={toggleSideMenuOpen} />
                </div>) :
                    (<SplitPane minSize={20} size={size} split="vertical" onDragFinished={onDragFinished}>
                        <PublicationHierarchy />
                        <PublicationContent handleOpenSideMenu={toggleSideMenuOpen} />
                    </SplitPane>)}
        </div>
    );
}

export default PublicationPage;
