import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import {
    getDocumentContent,
    getDocumentHierarchy,
    getDocumentProperties,
    getDocumentHierarchyPath
} from '../../API/Api';
import { RootState } from '../rootReducer';

const EmptyGuid: string = '00000000-0000-0000-0000-000000000000';

export interface Document {
    documentGuid: string;
    nextDocumentGuid: string | null;
    normCite: string;
    number: string;
    previousDocumentGuid: string | null;
    title: string;
};

export interface Hierarchy {
    hierarchyGuid: string;
    documentGuid: string;
    normCite: string;
    number: string;
    state: number;
    title: string;
    hasChildren: boolean;
    children: Hierarchy[];
    expanded?: boolean;
};

export interface Publication {
    sourceId: string;
    content: string | null | undefined;
    previousDocumentId: string | null;
    nextDocumentId: string | null;
    hierarchy: Hierarchy[];
}

interface PublicationState {
    publications: { [sourceIe: string]: Publication };
}

const initialState: PublicationState = {
    publications: {}
}

const findNode = (documentId: string, children: Hierarchy[]): Hierarchy | null => {
    let result: Hierarchy | null = null;
    let index = 0;

    while (index < children.length && !result) {
        const child = children[index++];

        if (child.documentGuid === documentId) {
            result = child;
        }
        else if (child.hasChildren && child.children !== null) {
            result = findNode(documentId, child.children);
        }
    }

    return result;
}

const getPublicationState = (state: RootState) => state.publication;

export const getNextDocumentId = (sourceId: string | null | undefined) => {
    return createSelector(
        getPublicationState,
        (state) => sourceId ? state.publications[sourceId]?.nextDocumentId : null
    );
}

export const getPreviousDocumentId = (sourceId: string | null | undefined) => {
    return createSelector(
        getPublicationState,
        (state) => sourceId ? state.publications[sourceId]?.previousDocumentId : null
    );
}

export const getPublication = (sourceId: string | null | undefined) => {
    return createSelector(
        getPublicationState,
        (state) => sourceId ? state.publications[sourceId] : null
    );
}

export const getPublicationContent = (sourceId: string | null | undefined) => {
    return createSelector(
        getPublicationState,
        (state) => sourceId ? state.publications[sourceId]?.content : null
    );
}

export const getPublicationHierarchy = (sourceId: string | null | undefined) => {
    return createSelector(
        getPublicationState,
        (state) => sourceId ? state.publications[sourceId]?.hierarchy : null
    );
}

export const getTitleDocumentId = (sourceId: string | null | undefined) => {
    return createSelector(
        getPublicationState,
        (state) => sourceId ? state.publications[sourceId]?.hierarchy[0]?.documentGuid : null
    );
}

const publicationSlice = createSlice({
    name: 'publication',
    initialState,
    reducers: {
        clear(state) {
            state.publications = {};
        },
        collapseNode(state, action: PayloadAction<{ sourceId: string, documentId: string }>) {
            const publication = state.publications[action.payload.sourceId];

            if (publication) {
                const node = findNode(action.payload.documentId, publication.hierarchy);
                if (node && node.expanded) {
                    node.expanded = false;
                }
            }
        },
        expandNode(state, action: PayloadAction<{ sourceId: string, documentId: string }>) {
            const publication = state.publications[action.payload.sourceId];

            if (publication) {
                const node = findNode(action.payload.documentId, publication.hierarchy);
                if (node && !node.expanded) {
                    node.expanded = true;
                }
            }
        },
        setNodeHierarchy(state, action: PayloadAction<{ sourceId: string, documentId: string, hierarchy: Hierarchy[] }>) {
            const publication = state.publications[action.payload.sourceId];

            if (publication) {
                const node = findNode(action.payload.documentId, publication.hierarchy);
                if (node) {
                    node.children = action.payload.hierarchy;
                    node.expanded = true;
                }
            }
        },
        setDocumentProperties(state, action: PayloadAction<{ sourceId: string, document: Document} >) {
            const publication = state.publications[action.payload.sourceId];

            if (publication) {
                publication.nextDocumentId = action.payload.document.nextDocumentGuid !== EmptyGuid ? action.payload.document.nextDocumentGuid : null;
                publication.previousDocumentId = action.payload.document.previousDocumentGuid !== EmptyGuid ? action.payload.document.previousDocumentGuid : null;
            }
        },
        setPublication(state, action: PayloadAction<string>) {
            const publication = state.publications[action.payload];

            if (!publication) {
                state.publications[action.payload] = {
                    sourceId: action.payload,
                    content: '',
                    hierarchy: [],
                    nextDocumentId: null,
                    previousDocumentId: null
                }
            }
        },
        setPublicationContent(state, action: PayloadAction<{ sourceId: string, content: string }>) {
            const publication = state.publications[action.payload.sourceId];

            if (publication) {
                publication.content = action.payload.content;
            }
        },
        setPublicationHierarchy(state, action: PayloadAction<{ sourceId: string, hierarchy: Hierarchy[] }>) {
            const publication = state.publications[action.payload.sourceId];

            if (publication) {
                publication.hierarchy = action.payload.hierarchy;

                if (publication.hierarchy && publication.hierarchy.length) {
                    publication.hierarchy[0].expanded = true;
                }
            }
        }
    }
})

export const {
    clear,
    collapseNode,
    expandNode,
    setDocumentProperties,
    setNodeHierarchy,
    setPublicationContent,
    setPublicationHierarchy,
    setPublication
} = publicationSlice.actions;

export const expandHierarchy = (sourceId: string, documentId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const publication = state.publication.publications[sourceId];

    if (state.authentication.userToken &&
        publication &&
        publication.hierarchy.length &&
        publication.sourceId === sourceId) {

        const node = findNode(documentId, [publication.hierarchy[0]]);
        if (node && !node.expanded && node.hasChildren) {
            const response = await getDocumentHierarchy(state.authentication.userToken, sourceId, node.hierarchyGuid, 1);
            dispatch(setNodeHierarchy({ sourceId, documentId, hierarchy: response?.data }));
        }
    }
}

export const collapseOrExpandHierarchy = (sourceId: string, documentId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const publication = state.publication.publications[sourceId];

    if (state.authentication.userToken &&
        publication &&
        publication.hierarchy.length &&
        publication.sourceId === sourceId) {

        const node = findNode(documentId, [publication.hierarchy[0]]);
        if (node) {
            if (node.expanded) {
                dispatch(collapseNode({ sourceId, documentId }));
            } else if (node.hasChildren) {
                if (null !== node.children) {
                    dispatch(expandNode({ sourceId, documentId }));
                } else {
                    const response = await getDocumentHierarchy(state.authentication.userToken, sourceId, node.hierarchyGuid, 1);
                    dispatch(setNodeHierarchy({ sourceId, documentId, hierarchy: response?.data }));
                }
            }
        }
    }
}

export const fetchDocumentProperties = (sourceId: string, documentId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();

    if (state.authentication.userToken) {
        const response = await getDocumentProperties(state.authentication.userToken, sourceId, documentId);
        dispatch(setDocumentProperties({ document: response?.data ?? '', sourceId }));
    }
}

export const fetchDocumentContent = (sourceId: string, documentId: string, searchText?: string): AppThunk => async (dispatch, getState) => {
    const state = getState();

    if (state.authentication.userToken) {
        const response = await getDocumentContent(state.authentication.userToken, sourceId, documentId, searchText);
        dispatch(setPublicationContent({ content: response?.data, sourceId }));
    }
}

export const fetchHierarchy = (sourceId: string, hierarchyId?: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const publication = state.publication.publications[sourceId];

    if (state.authentication.userToken &&
        (!publication ||
        !publication.hierarchy ||
        publication.hierarchy.length === 0 ||
        publication.sourceId !== sourceId)) {
        if (!hierarchyId) {
            hierarchyId = '00000000-0000-0000-0000-000000000000';
        }

        await dispatch(setPublication(sourceId));

        const response = await getDocumentHierarchy(state.authentication.userToken, sourceId, hierarchyId, 2);
        await dispatch(setPublicationHierarchy({ hierarchy: response?.data, sourceId }));
    }
}

export const selectHierarchyNode = (sourceId: string, documentId?: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const publication = state.publication.publications[sourceId];

    if (state.authentication.userToken &&
        publication &&
        publication.hierarchy &&
        publication.hierarchy.length &&
        publication.sourceId === sourceId &&
        documentId) {
        const documentHierarchy = JSON.parse(JSON.stringify(publication.hierarchy));
        const rootNode = documentHierarchy[0];

        var response = await getDocumentHierarchyPath(state.authentication.userToken, sourceId, documentId);
        if (response) {
            var changed: boolean = false;
            var hierarchy: Hierarchy | null = response.data;
            var currentNode: Hierarchy = rootNode;

            while (hierarchy) {
                var foundNode = findNode(hierarchy.documentGuid, [currentNode]);

                if (!foundNode) {
                    const response = await getDocumentHierarchy(state.authentication.userToken, sourceId, currentNode.hierarchyGuid, 1);
                    currentNode.children = response?.data;
                    changed = true;
                    foundNode = findNode(hierarchy.documentGuid, [currentNode]);
                }

                currentNode = foundNode!;

                if (!currentNode.expanded) {
                    await dispatch(expandHierarchy(sourceId, currentNode.documentGuid));
                    changed = true;
                }

                hierarchy = hierarchy.children && hierarchy.children.length ? hierarchy.children[0] : null;
            }

            if (changed) {
                await dispatch(setPublicationHierarchy(documentHierarchy));
            }
        }
    }
}

export default publicationSlice.reducer;
