import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import { userLogin } from '../../API/Api';
import { RootState } from '../rootReducer';

interface AuthenticationState {
    companyName: string | null;
    errorMessage: string | null;
    userIsLoggingIn: boolean;
    userIsLoggedIn: boolean;
    userIsTrial: boolean;
    userName: string | null;
    userToken: string | null;
}

interface LoginPayload {
    companyName: string | null;
    expiryDate: string;
    id: string;
    isExpired: boolean;
    isTrial: boolean;
    name: string;
}

const initialState: AuthenticationState = {
    companyName: null,

    userIsLoggingIn: false,
    userIsLoggedIn: false,
    userIsTrial: true,
    userToken: null,
    userName: 'Unknown User',

    errorMessage: null
}

const getAuthenticationState = (state: RootState) => state.authentication;

export const getLoginErrorMessage = createSelector(getAuthenticationState, state => state.errorMessage);
export const getUserIsLoggedIn = createSelector(getAuthenticationState, state => state.userIsLoggedIn);
export const getUserIsTrial = createSelector(getAuthenticationState, state => state.userIsTrial);
export const getUserName = createSelector(getAuthenticationState, state => state.userName);
export const getUserAndCompanyName = createSelector(getAuthenticationState, state => state.userName + (state.companyName?.length ? ` (${state.companyName})` : '') );
export const getUserToken = createSelector(getAuthenticationState, state => state.userToken);

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        loginFailure(state, action: PayloadAction<string | null>) {
            state.companyName = null;
            state.errorMessage = action.payload;
            state.userIsLoggingIn = false;
            state.userIsTrial = true;
            state.userName = 'Unknown User';
            state.userToken = null;
        },
        loginStart(state) {
            state.userIsLoggingIn = true;
        },
        loginSuccess(state, action: PayloadAction<LoginPayload>) {
            state.companyName = action.payload.companyName;
            state.userToken = action.payload.id;
            state.userIsLoggingIn = false;
            state.userIsLoggedIn = true;
            state.userIsTrial = action.payload.isTrial;
            state.userName = action.payload.name;
            state.userToken = action.payload.id;
            state.errorMessage = null;

            localStorage.setItem('userName', action.payload.name);
            localStorage.setItem('userToken', action.payload.id);
        },
        logout(state) {
            state.userIsLoggedIn = false;
            state.userName = 'Unknown User';
            state.userToken = null;

            localStorage.removeItem('userName');
            localStorage.removeItem('userToken');
        }
    }
})

export const {
    loginFailure,
    loginStart,
    loginSuccess,
    logout
} = authenticationSlice.actions

export default authenticationSlice.reducer

export const login = (userName: string, password: string, rememberMe: boolean): AppThunk => async dispatch => {
    await dispatch(loginStart());
    await userLogin(userName, password, rememberMe).then (response => {
        const { companyName, expiryDate, id, isExpired, isTrial, name, hasEntitlements } = response.data;

        if (!hasEntitlements) {
            dispatch(loginFailure('The account you are logging in with has no assigned entitlements; please speak to your company administrator or email support@optimalegal.online!'))
        } else if (isExpired) {
            dispatch(loginFailure('The account you are logging in with has expired; please renew your subscription!'))
        } else {
            dispatch(loginSuccess({ companyName, expiryDate, id, isExpired, isTrial, name }));
        }
    }).catch(err => {
        let message = 'An unexpected error occurred; please contact support@optimalegal.online!';

        if (!window.navigator.onLine) {
            message = 'You do not appear to be connected to the internet; please check your connection!';
        } else if (err.response) {
            console.log(err.response);
            if (err.response.status === 404) {
                message = 'Login attempt failed, please verify the entered username and password!';
            }
        } else if (err.request) {
            // Request failure.
            console.log(err.request);
        } else {
            // Anything else.
        }

        dispatch(loginFailure(message));
    });
}
