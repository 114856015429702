import React, { ReactElement } from 'react';

export interface SideMenuProps {
    children: ReactElement;
    isOpen: boolean;
    toggleSideMenuOpen: () => void;
};

const SideMenu = ({ children, isOpen, toggleSideMenuOpen }: SideMenuProps) => {

    const handleClassNames = 'toggle-icon fa ' + (isOpen ? 'fa-chevron-left' : 'fa-chevron-right');
    const sideMenuClassNames = 'sidemenu ' + (isOpen ? 'sidemenu-open' : 'sidemenu-closed');

    return (
        <div>
            {isOpen && <div className="click-canvas" onClick={toggleSideMenuOpen} />}
            <div className={sideMenuClassNames}>
                <div className="row sidemenu-content">
                    <div className="col-12 full-height">
                        {children}
                    </div>
                </div>
                <div className="sidemenu-handle" onClick={toggleSideMenuOpen}>
                    <span className={handleClassNames} />
                </div>
            </div>
        </div>
    );
}

export default SideMenu;
