import React from 'react';

import appSettings from '../../../appSettings.json';

const Footer = () => {
    return <footer className={'container-fluid text-right bg-dark footer'}>
      Copyright&nbsp;&copy;&nbsp;2006-{new Date().getFullYear()}&nbsp;{appSettings.applicationCompany}.&nbsp;All&nbsp;rights&nbsp;reserved.&nbsp;v{appSettings.applicationVersion}
    </footer>;
};

export default Footer;