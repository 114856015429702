import { combineReducers } from '@reduxjs/toolkit';

import authenticationReducer from './Slices/AuthenticationSlice';
import libraryReducer from './Slices/LibrarySlice';
import publicationReducer from './Slices/PublicationSlice';
import uiReducer from './Slices/UISlice';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  library: libraryReducer,
  publication: publicationReducer,
  ui: uiReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
