import React from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import './App.css';
import HomePage from './Components/HomePage/HomePage';
import store from './AppStore/store'

import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-bootstrap-typeahead/css/Typeahead.css';

function App() {
  return (
    <div id="app" className={'flex-down full-height'}>
      <Provider store={store}>
        <BrowserRouter>
          <HomePage />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;