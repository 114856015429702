import React, { useEffect, useState } from 'react'

import { Card, Modal, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLoginErrorMessage } from '../../../AppStore/Slices/AuthenticationSlice';

interface LoginFormProps {
    onSubmit: (userName: string, password: string, rememberMe: boolean) => void;
}

const userNameKey = 'username';

const LoginForm = ({ onSubmit } : LoginFormProps) => {
    const [initialised, setInitialised] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState(false);
    const [rememberUserId, setRememberUserId] = useState(false);
    const [userName, setUserName] = useState<string>('');

    const passwordRef = React.useRef<HTMLInputElement>(null);

    const loginErrorMessage = useSelector(getLoginErrorMessage);

    const onChangeUserName = (event : React.FormEvent<HTMLInputElement>) => {
        setUserName (event.currentTarget.value);
    }

    const onChangePassword = (event : React.FormEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
    }

    const onFormSubmit = () => {
        if (rememberUserId) {
            localStorage.setItem(userNameKey, userName);
        } else {
            localStorage.removeItem(userNameKey);
        }

        if (!loggingIn) {
            setLoggingIn(true);
            onSubmit(userName, password, rememberMe);
            setLoggingIn(false);
        }
    }

    const onKeyDown = (event : React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onFormSubmit();
            event.preventDefault();
        }
    }

    const canSubmit = (userName && password) && !loggingIn;

    useEffect(() => {
        if (!initialised) {
            setInitialised(true);
            const storedUserName = localStorage.getItem(userNameKey);

            if (storedUserName && storedUserName.length) {
                setRememberUserId(true);
                setUserName(storedUserName);

                if (passwordRef?.current) {
                    passwordRef.current.focus();
                }
            }
        }
    }, [initialised]);

    return (
        <>
        {loginErrorMessage && <div className='alert-danger p-2'>{loginErrorMessage}</div>}
        <div className={'d-flex justify-content-center'}>
            <div className={'mt-4 col-xs-11 col-sm-9 col-md-7 col-lg-5'}>
                <Card no-body='true'>
                    <Tabs card='true'>
                        <Tab title='Login' active className="m-2">
                            <div className={'row'}>
                                <div className={'col-sm-12'}>
                                    <h2 className={'text-center text-dark'}><strong>Login</strong></h2><hr />
                                </div>
                            </div>
                            <div className={'form-group'}>
                                <div className={'input-group'}>
                                    <div className={'input-group-prepend'}>
                                        <span className={'input-group-text fa fa-user'}></span>
                                    </div>
                                    <input autoFocus type='text' id='username' value={userName} className={'form-control'} onChange={onChangeUserName} onKeyDown={onKeyDown} autoComplete="email" placeholder='Email address/Username' aria-label='Email address/Username' />
                                </div>
                            </div>
                            <div className={'form-group'}>
                                <div className={'input-group'}>
                                    <div className={'input-group-prepend'}>
                                        <span className={'input-group-text fa fa-lock'}></span>
                                    </div>
                                    <input ref={passwordRef} type='password' id='password' value={password} className={'form-control'} onChange={onChangePassword} onKeyDown={onKeyDown} autoComplete="current-password" placeholder='Password' aria-label='Password' />
                                </div>
                            </div>
                            <div className={'form-group'}>
                                <button type='submit' disabled={!canSubmit} onClick={onFormSubmit} className={'btn btn-primary btn-block btn-lg'}>Login to Optima Law</button>
                            </div>
                            <div className={'form-group'}>
                                <p>Forgotten your password or to change it click <a href="https://acme.optimalegal.online" rel="noopener noreferrer" target="_blank">here</a>.</p>
                            </div>
                            <div className={'form-group'}>
                                <input type="checkbox" checked={rememberUserId} onChange={() => setRememberUserId(!rememberUserId)} name="remember-user-id" />
                                <label htmlFor="remember-user-id">&nbsp;&nbsp;&nbsp;Remember email address/username.</label>
                            </div>

                            <div className={'form-group'} style={{ padding:'6pt 12pt', textAlign: 'center' }}>
                                <p>We are the only provider of <b>fully consolidated and up-to-date Zimbabwe legislation</b> and <b>judgments</b> all fully searchable and hyperlinked; please email us on <a href="mailto:sales@optimalegal.online">sales@optimalegal.online</a> with the number of users for a quote.</p>
                                <p>Follow us on Twitter at <a href="https://www.twitter.com/OptimaLaw" rel="noopener noreferrer" target="_blank"><img alt='twitter logo' src="/images/Twitter.png" width="20"/> https://www.twitter.com/OptimaLaw</a> for news and updates.</p>
                                <p>To create a free no obligation seven (7) day trial click <a href="https://acme.optimalegal.online/register" rel="noopener noreferrer" target="_blank">here</a>.</p>
                                <p style={{ color: 'darkgray' }}>Please email <a href="mailto:support@optimalegal.online">support@optimalegal.online</a> if you have any problems accessing the service or ideas for improvements.</p>
                            </div>
                        </Tab>
                    </Tabs>
                    </Card>
                </div>
                <Modal id='modalFreeTrialExpired' ok-only centered size='lg'>
                    <p className={'my-4'}>
                        The <b>free trial</b> has now ended and we hope that you have found it a useful resource through these extremely difficult times.<br/>
                        <br/>
                        Now is a great time to subscribe to a perfected <b>Publication of Research tools</b>, and ensure that you stay up-to-date weekly on all the changes to Zimbabwe Legislation, Judgments and Gazettes; our next update will be published on 1<sup>st</sup> August 2020.<br/>
                        <br/>
                        Please e-mail us now on <a href='mailto:sales@optimalegal.online'>sales@optimalegal.online</a> with the number of users you would like to have access for a quote.
                    </p>
                </Modal>
            </div>
        </>);
}

export default LoginForm