import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLibrary, getIsLibraryLoaded } from '../../AppStore/Slices/LibrarySlice';

import Footer from './Footer/Footer';
import Menu from './Menu/Menu';

import CaseSearchPage from '../CaseSearchPage/CaseSearchPage';
import LibraryPage from '../LibraryPage/LibraryPage';
import LoginPage from '../LoginPage/LoginPage';
import PublicationPage from '../PublicationPage/PublicationPage';
import SearchPage from '../SearchPage/SearchPage';
import { getUserToken } from '../../AppStore/Slices/AuthenticationSlice';

const HomePage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isLibraryLoaded = useSelector(getIsLibraryLoaded);
    const userToken = useSelector(getUserToken);

    useEffect(() => {
        if (!userToken) {
            localStorage.setItem('login-redirect', history.location.pathname + history.location.search);
            history.push('/login');
            return;
        }

        if (!isLibraryLoaded) {
            dispatch(fetchLibrary(userToken));
        }

        if (history.location.pathname === '/') {
            history.push('/library');
        }
    }, [dispatch, history, isLibraryLoaded, userToken])

    return (
        <div className="flex-down full-height">
            <Menu/>
            <main className={'main'}>
                <Switch>
                    <Route path='/caseSearch' component={CaseSearchPage}/>
                    <Route path='/library' component={LibraryPage}/>
                    <Route path='/login' component={LoginPage}/>
                    <Route path='/publication/:sourceId/:documentId?' component={PublicationPage}/>
                    <Route path='/search/:searchText?' component={SearchPage}/>
                </Switch>
            </main>
            <Footer/>
        </div>);
};

export default HomePage;