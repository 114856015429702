import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

const CaseSearchPage = () => {

    const [attorneys, setAttorneys] = useState<string>('');
    const [caseName, setCaseName] = useState<string>('');
    const [caseNumber, setCaseNumber] = useState<string>('');
    const [caseText, setCaseText] = useState<string>('');
    const [catchwords, setCatchwords] = useState<string>('');
    const [counsel, setCounsel] = useState<string>('');
    const [court, setCourt] = useState<string>('');
    const [judge, setJudge] = useState<string>('');
    const [judgmentDate, setJudgmentDate] = useState<string>('');
    const [parties, setParties] = useState<string>('');
    const [summary, setSummary] = useState<string>('');

    const history = useHistory();

    const getSearchTerm = (term: string) => {
        if (term.includes(' AND ') || term.includes(' OR ') || term.includes(' NOT ')) {
            return `(${term})`;
        } else if (term.includes(' ')) {
            return `"${term}"`;
        } else {
            return term;
        }
    }

    const doSearch = () => {
        var term = '';

        if (caseNumber.length) {
            term += 'caseNumber:' + getSearchTerm(caseNumber);
        }

        if (caseName.length) {
            if (term.length) term += ' AND ';
            term += 'title:' + getSearchTerm(caseName);
        }

        if (judge.length) {
            if (term.length) term += ' AND ';
            term += 'judges:' + getSearchTerm(judge);
        }

        if (court.length) {
            if (term.length) term += ' AND ';
            term += 'court:' + getSearchTerm(court);
        }

        if (judgmentDate.length) {
            if (term.length) term += ' AND ';
            term += 'judgmentDate:' + getSearchTerm(judgmentDate);
        }

        if (parties.length) {
            if (term.length) term += ' AND ';
            term += 'parties:' + getSearchTerm(parties);
        }

        if (catchwords.length) {
            if (term.length) term += ' AND ';
            term += 'catchwords:' + getSearchTerm(catchwords);
        }

        if (summary.length) {
            if (term.length) term += ' AND ';
            term += 'summary:' + getSearchTerm(summary);
        }

        if (counsel.length) {
            if (term.length) term += ' AND ';
            term += 'counsel:' + getSearchTerm(counsel);
        }

        if (attorneys.length) {
            if (term.length) term += ' AND ';
            term += 'attorneys:' + getSearchTerm(attorneys);
        }

        if (caseText.length) {
            if (term.length) term += ' AND ';
            term += getSearchTerm(caseText);
        }

        history.push(`/search/${term}?where=Judgments`);
    }

    const isValid = caseName.length || caseNumber.length || judge.length || court.length || judgmentDate.length ||
        parties.length || catchwords.length || summary.length || counsel.length || caseText.length || attorneys.length;

    const onKeyDown = (event: any) => {
        if (event.keyCode === 13 && isValid) {
            doSearch();
        }
    }
    
    return (
        <div className="d-flex justify-content-center">
            <div className="mt-4 col-xs-12 col-sm-8 col-md-6 col-lg-4">
                <div className="form-group">
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Case Number:</span>
                            <input autoFocus type="text" id="caseNumber" value={caseNumber} className="form-control"
                                placeholder="Case Number; i.e. 20-HH-287" aria-label="Case Number"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setCaseNumber(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Case Name:</span>
                            <input type="text" id="caseName" value={caseName} className="form-control" placeholder="Case Name" aria-label="Case Name"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setCaseName(e.currentTarget.value)}  onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Judge:</span>
                            <input type="text" id="judge" value={judge} className="form-control" placeholder="Judge" aria-label="Judge"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setJudge(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Court:</span>
                            <input type="text" id="court" value={court} className="form-control" placeholder="Court" aria-label="Court"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setCourt(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Judgment Date:</span>
                            <input type="text" id="judgmentDate" value={judgmentDate} className="form-control" placeholder="Judgment Date" aria-label="Judgment Date"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setJudgmentDate(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Parties:</span>
                            <input type="text" id="parties" value={parties} className="form-control" placeholder="Parties" aria-label="Parties"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setParties(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Catchwords:</span>
                            <input type="text" id="catchwords" value={catchwords} className="form-control" placeholder="Catchwords" aria-label="Catchwords"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setCatchwords(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Summary:</span>
                            <input type="text" id="summary" value={summary} className="form-control" placeholder="Summary" aria-label="Summary"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setSummary(e.currentTarget.value)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Counsel:</span>
                            <input type="text" id="counsel" value={counsel} className="form-control" placeholder="Counsel" aria-label="Counsel"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setCounsel(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Case Text:</span>
                            <input type="text" id="caseText" value={caseText} className="form-control" placeholder="Case Text" aria-label="CaseText"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setCaseText(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text" style={{ width: '150px', borderRadius: '5px 0 0 5px' }}>Attorneys:</span>
                            <input type="text" id="attorneys" value={attorneys} className="form-control" placeholder="Attorneys" aria-label="Attorneys"
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setAttorneys(e.currentTarget.value)} onKeyDown={(e: any) => onKeyDown(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <button type="submit" className="btn btn-primary btn-block btn-lg" disabled={!isValid} onClick={() => doSearch()}>Search</button>
                        </div>
                    </div>
                    <div style={{ color: 'gray', fontStyle: 'italic', textAlign: 'center'}}>Or enter text and press the Enter key to invoke the search.</div>
                </div>
            </div>
        </div>
    )
}

export default CaseSearchPage;
