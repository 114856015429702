import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { getUserAndCompanyName, getUserIsLoggedIn, logout } from '../../../AppStore/Slices/AuthenticationSlice'

const Menu = () => {
    const userIsLoggedIn = useSelector(getUserIsLoggedIn);
    const userAndCompanyName = useSelector(getUserAndCompanyName);
    const dispatch = useDispatch();
    const history = useHistory();

    const onLogoutRequest = () => {
        dispatch(logout());
        setTimeout(() => {
            history.push("/login");
        }, 200);
    }

    const onShowProfile = () => {
        window.open('https://acme.optimalegal.online', '_blank');
    }

    return (
        <Navbar expand="md" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Brand>
                    <img src="/images/netpub_white_24.png" height="28" width="24" alt="lib" className={"mr-2 align-top"} />Optima Law
                </Navbar.Brand>
            <Navbar.Collapse is-nav="true" id="nav_collapse">
                <Nav>
                    { userIsLoggedIn ?
                        (<>
                            <Link style={{marginRight: '16px'}} to="/library">Library</Link>
                            <Link style={{marginRight: '16px'}} to="/caseSearch">Case Search</Link>
                            <Link style={{marginRight: '16px'}} to="/search">Search</Link>
                        </>) :
                        (<Link style={{marginRight: '16px'}} to="/login">Login</Link>)
                    }
                </Nav>

                {userIsLoggedIn && <Nav className={'ml-auto'}>
                    <NavDropdown title={userAndCompanyName} id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={onShowProfile}>Profile</NavDropdown.Item>
                        <NavDropdown.Item onClick={onLogoutRequest}>Signout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>}
                {!userIsLoggedIn && <Nav className={'ml-auto'}><span style={{color: 'lightgray'}}>Unknown User</span></Nav>}
            </Navbar.Collapse>
        </Navbar>);
}

export default Menu;