import React from 'react';
import { Hierarchy } from '../../AppStore/Slices/PublicationSlice';

interface PublicationHierarchyNodeProps {
    id: string;
    node: Hierarchy;
    onExpandOrCollapse: (documentId: string) => void;
    onSelect: (documentId: string) => void;
    selectedDocumentId: string | null | undefined;
};

const PublicationHierarchyNode = ({ node, onExpandOrCollapse, onSelect, selectedDocumentId }: PublicationHierarchyNodeProps) => {

    const renderChildren = (hierarchy: Hierarchy[]) => {
        return (<ul>{hierarchy.map(child =>
            <PublicationHierarchyNode key={child.documentGuid} id={child.documentGuid} node={child} onExpandOrCollapse={onExpandOrCollapse} onSelect={onSelect} selectedDocumentId={selectedDocumentId} />)}</ul>);
    };

    const handleExpandOrCollapse = () => {
        if (onExpandOrCollapse) {
            onExpandOrCollapse(node.documentGuid);
        }
    }

    const handleSelect = () => {
        if (onSelect) {
            onSelect(node.documentGuid);
        }
    }

    const fontWeight = selectedDocumentId === node.documentGuid ? 'bold' : 'normal';
    const color = node.state === 1 ? 'darkred' : node.state === 2 ? 'gray' : 'initial';
    const title = node.number && node.number.length && node.title && node.title.length ? `${node.number} ${node.title}` : (node.title && node.title.length ? node.title : node.number ?? '');
    const whiteSpace = 'normal'; // nowrap

    const renderIcon = () => {
        if (!node.hasChildren) {
            return <i className='fa fa-file-text-o' onClick={handleExpandOrCollapse} />
        } else if (node.expanded) {
            return <i className='fa fa-chevron-down' onClick={handleExpandOrCollapse} />
        } else {
            return <i className='fa fa-chevron-right' onClick={handleExpandOrCollapse} />
        }
    };

    return (
        <li style={{ color, fontWeight, whiteSpace }}>
            {renderIcon()}&nbsp;&nbsp;
            <span className="link" id={node.documentGuid} style={{ color }} onClick={handleSelect}>{title}</span>
            {node.expanded && node.children && renderChildren(node.children)}
        </li>
    );
}

export default PublicationHierarchyNode;
