import React from 'react';
import Book from './Book';
import { LibraryBook } from '../../AppStore/Slices/LibrarySlice';

interface LibraryPaneProps {
    libraryBooks: LibraryBook[]
};

const LibraryPane = ({ libraryBooks } : LibraryPaneProps) => {
    return (
        <div className="carousel col-12 publication-list d-flex flex-wrap">
            {libraryBooks.map(libraryBook => <Book key={libraryBook.sourceGuid} libraryBook={libraryBook} />)}
        </div>
    )
}

export default LibraryPane;