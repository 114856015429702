import React from 'react';
import { Link } from 'react-router-dom';

import { LibraryBook } from '../../AppStore/Slices/LibrarySlice';

interface BookProps {
    libraryBook: LibraryBook;
};

const Book = ({libraryBook} : BookProps) => {
    return (<div className="figure">
                <Link to={`/publication/${libraryBook.sourceGuid}`}>
                    <img src={`https://www.optimalegal.online${libraryBook.coverImageUrl}`}
                        className="figure-img img-fluid rounded" alt={libraryBook.title}/>
                    <span className="figure-caption text-center">{libraryBook.title}</span>
                </Link>
            </div>);
            
};

export default Book;
