import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import { fetchUserLibrary } from '../../API/Api';
import { RootState } from '../rootReducer';
import { setPublication } from './PublicationSlice';

export interface LibraryBook {
    sourceGuid: string;
    title: string;
    coverImageUrl: string;
    smallImageUrl: string;
    isVisible: boolean;
    updateDate: Date;
}

interface LibraryState {
    library: LibraryBook[];
    isLoading: boolean;
    loaded: boolean;
}

const initialState: LibraryState = {
    library: [],
    isLoading: false,
    loaded: false
}

const getLibraryState = (state: RootState) => state.library;

export const getLibraryBook = (sourceId: string) => createSelector(getLibraryState, state => state.library.find(book => book.sourceGuid === sourceId));
export const getLibraryBooks = createSelector(getLibraryState, state => state.library);
export const getIsLibraryLoaded = createSelector(getLibraryState, state => state.loaded);

export const getLibraryBooksMap = createSelector(getLibraryState, state => {
    const result: { [id: string]: LibraryBook } = {};

    state.library?.forEach(book => {
        result[book.sourceGuid] = book;
    })

    return result;
});

const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
        loadLibraryFailure(state) {
            state.isLoading = false;
            state.loaded = false;
        },
        loadLibraryStart(state) {
            state.isLoading = true;
            state.loaded = false;
        },
        loadLibrarySuccess(state, action: PayloadAction<LibraryBook[]>) {
            state.library = action.payload;
            state.isLoading = false;
            state.loaded = true;
        },
    }
})

export const {
    loadLibraryFailure,
    loadLibraryStart,
    loadLibrarySuccess
} = librarySlice.actions

export default librarySlice.reducer

export const fetchLibrary = (userToken: string): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(loadLibraryStart());
        const response = await fetchUserLibrary(userToken);
        dispatch(loadLibrarySuccess(response.data));

        try {
            const state = getState();

            state.library.library.forEach(libraryBook => {
                dispatch(setPublication(libraryBook.sourceGuid));
            });
        }
        catch (err) {
            console.log(err);
        }
    }
    catch (err) {
        dispatch(loadLibraryFailure())
    }
}