import React from 'react';
import { useSelector } from 'react-redux';
import { getLibraryBooks } from '../../AppStore/Slices/LibrarySlice';

import LibraryPane from './LibraryPane';
import SearchBar from '../SearchPage/SearchBar';

const LibraryPage = () => {
    const libraryBooks = useSelector(getLibraryBooks);

    return (
        <div className="container mt-3">
            <div className="ml-3">
                <SearchBar searchResults={true} />
            </div>
            <div className="mb-1">
                <LibraryPane libraryBooks={libraryBooks}/>
            </div>
        </div>
    )
}

export default LibraryPage;