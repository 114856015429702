import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AnnotationHide } from '../../assets/annotation-off.svg';
import { ReactComponent as AnnotationShow } from '../../assets/annotation.svg';
import { exportDocument, getDocumentHierarchyPath } from '../../API/Api';
import { Hierarchy } from '../../AppStore/Slices/PublicationSlice';
import { useSelector } from 'react-redux';
import { getUserIsTrial, getUserToken } from '../../AppStore/Slices/AuthenticationSlice';

import Modal from 'react-bootstrap/Modal';

interface PublicationHeaderProps {
    hasNextDocument: boolean;
    hasPreviousDocument: boolean;
    onNextDocument: () => void;
    onPreviousDocument: () => void;
    onToggleAnnotations: () => void;
    searchResults: HTMLCollectionOf<Element> | undefined;
    showAnnotations: boolean;
}

const PublicationHeader = (
    {
        hasNextDocument, hasPreviousDocument, onNextDocument, onPreviousDocument, onToggleAnnotations, searchResults, showAnnotations
    }: PublicationHeaderProps) => {

    const history = useHistory();

    const { documentId, sourceId } = useParams<{ sourceId: string, documentId: string | undefined }>();

    const [exporting, setExporting] = useState(false);
    const [hierarchy, setHierarchy] = useState<Hierarchy[]>([]);
    const [showExportMessage, setShowExportMessage] = useState(false);

    const userIsTrial = useSelector(getUserIsTrial);
    const userToken = useSelector(getUserToken);

    useEffect(() => {
        let isMounted = true;

        if (documentId && sourceId && userToken) {
            getDocumentHierarchyPath(userToken, sourceId, documentId)?.then((response) => {
                var current: Hierarchy | null = response.data;
                var stack: Hierarchy[] = [];

                while (current) {
                    stack.push(current);

                    current = current.children !== null ? current.children[0] : null;
                }

                if (isMounted) {
                    setHierarchy(stack);
                }
            });
        }

        return () => { isMounted = false };
    }, [documentId, sourceId, userToken]);

    const handleExportClick = (contentType: string) => {
        const exportIt = async () => {
            if (documentId && userToken) {
                setExporting(true);
                await exportDocument(userToken, sourceId, documentId, showAnnotations, contentType);
                setExporting(false);
            }
        };

        if (userIsTrial) {
            setShowExportMessage(true);
        } else {
            exportIt();
        }
    };

    const handleHierarchItemClick = (item: Hierarchy) => {
        history.push(`/publication/${sourceId}/${item.documentGuid}`);
    };

    return (
        <>
        {exporting &&
            <div v-if="isBusy" className="click-canvas">
                <div className="alert alert-info fixed-bottom" role="alert">
                    Please wait generating your document.
                </div>
            </div>}
        <div className="card-header">
            <ul className="list-inline mb-0">
            <li className="list-inline-item">
                <button className="buttonLink list-inline-item" onClick={() => onPreviousDocument()} disabled={!hasPreviousDocument}><FontAwesomeIcon icon={faChevronUp as IconProp}/></button>
                <button className="buttonLink list-inline-item" onClick={() => onNextDocument()} disabled={!hasNextDocument}><FontAwesomeIcon icon={faChevronDown as IconProp}/></button>
            </li>
            {hierarchy && hierarchy.map((item, index) => {
                const label = (item.number ? item.number + ' ' : '') + item.title;
                return index < hierarchy.length - 1 ?
                    <span key={item.documentGuid}>
                        <span className="link" onClick={() => handleHierarchItemClick(item)}>{label}</span> <i className="fa fa-angle-double-right mr-1" style={{ color: 'gray'}} />
                    </span>
                    : <span key={item.documentGuid}><b>{label}</b></span>;
            })}
            <li className="list-inline-item float-right">
                {searchResults?.length ? (<span>({searchResults.length} search results)&nbsp;&nbsp;</span>) : <></>}
                <button className="buttonLink list-inline-item" onClick={() => handleExportClick('application/docx')}>
                    <i className='fa fa-file-word-o' style={{color: 'blue' }} title="Download Word document"/>
                </button>
                <button className="buttonLink list-inline-item" onClick={() => handleExportClick('application/pdf')}>
                    <i className='fa fa-file-pdf-o' style={{ color: 'red' }} title="Download PDF document"/>
                </button>
                <button className="buttonLink list-inline-item" onClick={onToggleAnnotations} >
                {showAnnotations ?
                    <AnnotationShow title="Toggle annotation display" height="16" width="16"/> :
                    <AnnotationHide title="Toggle annotation display" height="16" width="16"/>}
                </button>
            </li>
            </ul>
        </div>
        <Modal id="modalExportDocuments" show={showExportMessage} ok-only centered>
            <Modal.Header>Optima Law - Save Document</Modal.Header>
            <Modal.Body>Downloading of enactments and provisions in Word and PDF document formats is disabled in the trial version.</Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary btn-sm" onClick={() => { setShowExportMessage(false); }}>OK</button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default PublicationHeader;
