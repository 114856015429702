import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIsLoggedIn, login, loginSuccess } from '../../AppStore/Slices/AuthenticationSlice';

import LoginForm from './LoginForm/LoginForm'
import { silentLogin } from '../../API/Api'

const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userIsLoggedIn = useSelector(getUserIsLoggedIn);

    const [triedSilentLogin, setTriedSilentLogin] = useState(false);

    useEffect(() => {
        const trySilentLogin = async () => {
            setTriedSilentLogin(true);

            await silentLogin(null)
                .then(response => {
                    dispatch(loginSuccess(response.data));
                })
                .catch(error => {
                    console.log(error);
                });
        };

        if (userIsLoggedIn) {
            let loginRedirect = localStorage.getItem('login-redirect') ?? '';
            localStorage.removeItem('login-redirect');

            if (loginRedirect === null || !loginRedirect.length || loginRedirect === '/login') {
                loginRedirect = '/library';
            }

            history.push(loginRedirect);
        } else if (!triedSilentLogin) {
            trySilentLogin();
        }
    }, [dispatch, history, triedSilentLogin, userIsLoggedIn]);

    const onSubmit = (userName: string, password: string, rememberMe: boolean) => {
        const tryToLoginUser = async () => {
            await dispatch(login(userName, password, rememberMe));
        }

        tryToLoginUser();
    }

    return (<LoginForm onSubmit={onSubmit} />);
}

export default LoginPage;