import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import { search } from '../../API/Api';
import { RootState } from '../rootReducer';

export interface SearchResult {
    documentGuid: string;
    documentTitle: string;
    rank: number;
    sourceGuid: string;
    title: string;
}

export interface SearchWhere {
    Gazettes: boolean;
    IsItInforce: boolean;
    Judgments: boolean;
    Regulations: boolean;
    Statutes: boolean;
};

const initialSearchWhereState = {
    Gazettes: false,
    IsItInforce: false,
    Judgments: true,
    Regulations: true,
    Statutes: true
};

interface SearchPayload {
    searchText: string;
    searchWhere: SearchWhere;
}

interface SearchState {
    searchResults: SearchResult[] | null;
    searchResultSelected: number | undefined;
    searchText: string;
    searchWhere: SearchWhere;
}

interface uiState extends SearchState {
}

const initialState: uiState = {
    // Search.
    searchResults: null,
    searchResultSelected: undefined,
    searchText: '',
    searchWhere: initialSearchWhereState
}

export const getSearch = (state: RootState): SearchPayload => {
    return {
        searchText: state.ui.searchText,
        searchWhere: state.ui.searchWhere
    };
}

export const getSearchResults = (state: RootState): SearchResult[] | null => state.ui.searchResults;
export const getSearchResultSelected = (state: RootState): number | null | undefined => state.ui.searchResultSelected;
export const getSearchText = (state: RootState): string | null | undefined => state.ui.searchText;

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        clearSearch(state) {
            state.searchText = '';
            state.searchWhere = initialSearchWhereState;
            state.searchResults = null;
        },
        setSearch(state, action: PayloadAction<SearchPayload>) {
            state.searchText = action.payload.searchText;
            state.searchWhere = action.payload.searchWhere;
            state.searchResults = null;
        },
        searchFailure(state) {
            state.searchResults = [];
        },
        searchStart(state) {
            state.searchResultSelected = undefined;
        },
        searchSuccess(state, action: PayloadAction<SearchResult[]>) {
            state.searchResults = action.payload ?? [];
        },
        setSearchResultSelected(state, action: PayloadAction<number | undefined>) {
            state.searchResultSelected = action.payload;
        }
    }
})

export const {
    clearSearch,
    setSearch,
    searchFailure,
    searchStart,
    searchSuccess,
    setSearchResultSelected
} = uiSlice.actions

export const doSearch = (searchText: string, where: string | null): AppThunk => async (dispatch, getState) => {
    const state = getState();

    if (state.authentication.userToken) {
        try {
            dispatch(searchStart())
            const response = await search(state.authentication.userToken, searchText, where);
            dispatch(searchSuccess(response?.data));
        }
        catch (err) {
            dispatch(searchFailure());
        }
    }
}

export default uiSlice.reducer
