import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getIsLibraryLoaded, getLibraryBooksMap } from '../../AppStore/Slices/LibrarySlice';
import { doSearch, getSearch, getSearchResults, getSearchResultSelected, SearchResult, setSearchResultSelected } from '../../AppStore/Slices/UISlice';

import SearchBar from './SearchBar';
import SearchResultItem from './SearchResultItem';

const useQueryString = () => new URLSearchParams(useLocation().search);

const SearchPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryString = useQueryString();

    const isLibraryLoaded = useSelector(getIsLibraryLoaded);
    const mapLibraryBooks = useSelector(getLibraryBooksMap);
    const search = useSelector(getSearch);
    const searchResults = useSelector(getSearchResults);
    const searchResultSelected = useSelector(getSearchResultSelected);
    const where = queryString.get('where');

    const { searchText } = useParams<{ searchText: string | undefined }>();
    const searchTerm: string | undefined = searchText ? decodeURIComponent(searchText) : search?.searchText;

    const [currentUrl, setCurrentUrl] = React.useState('');

    const url = history.location.pathname + history.location.search;

    useEffect(() => {
        if (searchTerm?.length) {
            const search: boolean = !searchResults || url !== currentUrl;

            if (search) {
                dispatch(doSearch(searchTerm, where));
                setCurrentUrl(url);
            }
        }
    }, [currentUrl, dispatch, searchResults, searchTerm, url, where]);

    useEffect(() => {
        if (searchResults && searchResultSelected) {
            const searchResult = searchResults[searchResultSelected];
            const searchResultElement = document.getElementById(searchResult.documentGuid);
            if (searchResultElement) {
                searchResultElement.scrollIntoView({ behavior: 'auto', block: 'nearest' });
            }
        }
    }, [searchResultSelected, searchResults])

    const searchResultClick = (sourceId: string, documentId: string) => {
        const index = searchResults?.findIndex(item => item.documentGuid === documentId);
        dispatch(setSearchResultSelected(index));

        history.push(`/publication/${sourceId}/${documentId}` + (searchTerm ? '?h=' + encodeURIComponent(searchTerm) : ''));
    }

    const buildSearchResults = (searchResults: SearchResult[]) => {
        if (searchResults.length === 0) {
            return (
                <div style={{ fontSize: '16pt', fontWeight: 600, borderBottom: 'solid 1px gray', marginBottom: '16pt'}}>
                    <i className='fa fa-list' style={{ paddingRight: '8pt' }} />No search results found; please refine your search!
                </div>);
        }

        const results = searchResults.map((result, index) => {
            const libraryBook = mapLibraryBooks[result.sourceGuid];
            return (
                <SearchResultItem key={result.documentGuid}
                    item={result} selected={searchResultSelected === index} onClick={searchResultClick} sourceTitle={libraryBook.title}
                    sourceImageUrl={`https://www.optimalegal.online${libraryBook.smallImageUrl}`} />);
            });

        return (
            <>
            <div style={{ fontSize: '16pt', fontWeight: 600, borderBottom: 'solid 1px gray', marginBottom: '16pt'}}>
                <i className='fa fa-list' style={{ paddingRight: '8pt' }} />
                {results.length}{results.length === 1000 ? '+' : ''} search result{results.length === 1 ? '' : 's'}
            </div>
            {results}
            </>);
    }

    return (
        <div className="container mt-3">
            <div className="ml-3">
                <SearchBar initialSearchText={searchTerm} searchResults={searchResults ? searchResults.length !== 0 : false} />
            </div>
            <div>
                {isLibraryLoaded && searchResults && buildSearchResults(searchResults)}
            </div>
        </div>
    )
}

export default SearchPage;
