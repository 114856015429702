import React, { TouchEvent, useEffect, useState } from 'react';

import { getUserIsTrial } from '../../AppStore/Slices/AuthenticationSlice';
import { fetchDocumentContent, fetchDocumentProperties, getNextDocumentId, getPreviousDocumentId, getPublication, getPublicationContent } from '../../AppStore/Slices/PublicationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getSearch } from '../../AppStore/Slices/UISlice';

import PublicationHeader from './PublicationHeader';

const useQueryString = () => new URLSearchParams(useLocation().search);

interface PublicationContentProps {
    handleOpenSideMenu: () => void;
};

const PublicationContent = ({ handleOpenSideMenu }: PublicationContentProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const queryString = useQueryString();
    const documentScrollViewport = React.useRef<HTMLDivElement>(null);
    const p = queryString.get('p'); // Scroll position.
    const highlight = queryString.get('h'); // Highlight text.

    const { documentId, sourceId } = useParams<{ sourceId: string, documentId: string | undefined }>();

    const [currentDocumentId, setCurrentDocumentId] = useState<string | null>(null);
    const [showAnnotations, setShowAnnotations] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    const documentContent: string | null | undefined = useSelector(getPublicationContent(sourceId));
    const nextDocumentId = useSelector(getNextDocumentId(sourceId));
    const previousDocumentId = useSelector(getPreviousDocumentId(sourceId));
    const publication = useSelector(getPublication(sourceId));
    const searchContext = useSelector(getSearch);
    const userIsTrial = useSelector(getUserIsTrial);

    const scrollToHashTag = (hashTag: string) => {
        const element = document.getElementById(hashTag);
        if (element) {
            element.scrollIntoView();
        }
    }

    useEffect(() => {
        const current = documentScrollViewport.current;

        const handleClick = (e: any) => {
            if (null !== publication) {
                let href: string = e.target.href;

                if (!href) {
                    let parent = e.target.parentNode;
                    while (parent && !href) {
                        href = parent.href;
                        parent = parent.parentNode;
                    }
                }

                if (href) {
                    let hashTag: string | null = null;
                    const indexHash = href.indexOf('#');
                    if (indexHash > 0) {
                        hashTag = href.substring(indexHash + 1);
                        href = href.substring(0, indexHash);
                    }

                    const parts: string[] = href.split('/');
                    if (parts.length === 6 && parts[3] === 'publication') {
                        e.preventDefault();

                        if (scrollPosition) {
                            history.push(`/publication/${sourceId}/${documentId}?p=${scrollPosition}`);
                        }

                        history.push(`/${parts[3]}/${parts[4]}/${parts[5]}${hashTag ? '#' + hashTag : ''}`);
                    }
                    else if (parts.length === 5 && parts[3] === 'search') {
                        e.preventDefault();
                        history.push(`/${parts[3]}/${parts[4]}`);
                    } else if (scrollPosition) {
                        e.preventDefault();
                        history.push(`/publication/${sourceId}/${documentId}?p=${scrollPosition}`);
                    }

                    if (hashTag) {
                        scrollToHashTag(hashTag);
                    }
                }
            }
        }

        if (current) {
            current.addEventListener('click', handleClick);
        }

        return () => {
            if (current) {
                current.removeEventListener('click', handleClick);
            }
        }
    }, [documentId, history, publication, scrollPosition, sourceId])

    useEffect(() => {
        if (publication && documentId && documentId !== currentDocumentId) {
            setCurrentDocumentId(documentId);
            dispatch(fetchDocumentContent(sourceId, documentId, highlight ?? searchContext?.searchText));
            dispatch(fetchDocumentProperties(sourceId, documentId))

            if (p) {
                // We delay navigating to the position to allow the document to load.
                setTimeout(() => {
                    if (documentScrollViewport.current) {
                        documentScrollViewport?.current.scrollTo(0, parseInt(p));
                    }
                }, 100);
            } else if (location && location.hash) {
                scrollToHashTag(location.hash.substring(1));
            } else if (highlight) {
                const searchResults = documentScrollViewport.current?.getElementsByClassName('searchHit');
                if (searchResults && searchResults.length) {
                    setTimeout(() => {
                        if (searchResults && searchResults.length) {
                            searchResults[0].scrollIntoView();
                        }
                    }, 200);
                }
            }
        }
    }, [currentDocumentId, dispatch, documentId, highlight, location, p, publication, searchContext, showAnnotations, sourceId])

    useEffect(() => {
        if (!showAnnotations) {
            updateAnnotations(showAnnotations);
        }
    }, [documentContent, showAnnotations])

    useEffect(() => {
        if (location && location.hash) {
            // We use the same delay here so that the position and hash tag are processed in order.
            setTimeout(() => {
                scrollToHashTag(location.hash.substring(1));
            }, 100);
        } else if (p) {
            // We delay navigating to the position to allow the document to load.
            setTimeout(() => {
                if (documentScrollViewport.current) {
                    documentScrollViewport?.current.scrollTo(0, parseInt(p));
                }
            }, 100);
        } else if (!highlight) {
            // Scroll to the top of the document content.
            setTimeout(() => {
                if (documentScrollViewport.current) {
                    documentScrollViewport?.current.scrollTo(0, 0);
                }
            }, 100);
        }
    }, [documentScrollViewport, highlight, location, p])

    const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        if (userIsTrial) {
            event.preventDefault();
            event.stopPropagation();
        }

        return !userIsTrial;
    }

    const handleCopy = (event: React.ClipboardEvent<HTMLDivElement>) => {
        if (userIsTrial) {
            event.preventDefault();
            event.stopPropagation();
        }

        return !userIsTrial;
    }

    const handleNextDocument = () => {
        if (nextDocumentId) {
            history.push(`/publication/${sourceId}/${nextDocumentId}`)
        }
    }

    const handlePreviousDocument = () => {
        if (previousDocumentId) {
            history.push(`/publication/${sourceId}/${previousDocumentId}`)
        }
    }

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        setScrollPosition(event.currentTarget.scrollTop);
    }

    const handleToggleAnnotations = () => {
        setShowAnnotations(!showAnnotations);
        updateAnnotations(!showAnnotations);
    }

    const updateAnnotations = (show: boolean) => {
        if (documentScrollViewport.current) {
            const annotations: NodeListOf<HTMLElement> = documentScrollViewport.current.querySelectorAll('.annotation, .annotationListItem')
            if (annotations) {
                const display = show ? 'block' : 'none';
                annotations.forEach(annotation => {
                    annotation.style.display = (display);
                })
            }
        }
    }

    // Touch screen handlers:
    const [touchPoint, setTouchPoint] = useState<{ xDown: number, yDown: number } | null>(null);

    const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
        const touch = event.touches;
        setTouchPoint({ xDown: touch[0].clientX, yDown: touch[0].clientY })
    }

    const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
        if (!touchPoint) {
            return;
        }

        var xUp = event.touches[0].clientX;
        var yUp = event.touches[0].clientY

        var xDiff = (touchPoint.xDown - xUp)
        var yDiff = (touchPoint.yDown - yUp)

        if (Math.abs(xDiff) > Math.abs(yDiff)) { /* most significant */
            if (touchPoint.xDown < 50 && xDiff <= 0 && xDiff > -50) {
                handleOpenSideMenu();
            } else if (xDiff > 0) {
                handleNextDocument();
            } else {
                handlePreviousDocument();
            }
        }

        setTouchPoint(null);
    }

    return (
        <div className="full-height">
            <div className="card flex-me flex-down">
                <PublicationHeader hasNextDocument={nextDocumentId !== null} hasPreviousDocument={previousDocumentId !== null}
                    onNextDocument={handleNextDocument} onPreviousDocument={handlePreviousDocument}
                    onToggleAnnotations={handleToggleAnnotations} searchResults={documentScrollViewport.current?.getElementsByClassName('searchHit')}
                    showAnnotations={showAnnotations} />
                <div id="documentScrollViewport" className="card-body card-body-flush flex-me v-scroll optima-watermark"
                    onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}
                    ref={documentScrollViewport} onScroll={handleScroll} >
                    <div className="optima-watermark">
                        <div className="full-height" dangerouslySetInnerHTML={{ __html: documentContent ?? '' }}
                            onCopy={handleCopy} onContextMenu={handleContextMenu} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PublicationContent;
