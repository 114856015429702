interface act {
    guid: string;
    title: string;
};

const acts: act[] =
[
    { guid: "7FD6B21F-4B0C-44AD-A504-7A0BA85F0B8B", title: "ACCESS TO INFORMATION AND PROTECTION OF PRIVACY ACT [CHAPTER 10:27]" },
    { guid: "059FC1B7-8CE7-4725-ADDA-B1B4839F3FA2", title: "ACQUISITION OF FARM EQUIPMENT AND MATERIAL ACT [CHAPTER 18:23]" },
    { guid: "EEB7CC86-F3E4-4DA5-BB37-B938E76C28F4", title: "ADMINISTRATION OF ESTATES ACT [CHAPTER 6:01]" },
    { guid: "1496697B-9233-4B83-B484-C8FB0890E46F", title: "ADMINISTRATIVE COURT ACT [CHAPTER 7:01]" },
    { guid: "84CBAF40-782E-44BB-BBCA-E930828E41F5", title: "ADMINISTRATIVE JUSTICE ACT [CHAPTER 10:28]" },
    { guid: "E9D1912B-6888-42A9-9645-A7E84E83E65D", title: "ADVERTISEMENTS REGULATION ACT [CHAPTER 14:01]" },
    { guid: "BCFB22A4-A1D6-4670-A38A-0E6753C504CF", title: "AFRICAN DEVELOPMENT BANK (MEMBERSHIP OF ZIMBABWE) ACT [CHAPTER 22:01]" },
    { guid: "9BDEAF1A-B2F9-49EC-A96B-74BB739549CA", title: "AFRICAN DEVELOPMENT FUND (ZIMBABWE) ACT [CHAPTER 22:02]" },
    { guid: "DE55CC97-E886-4263-834D-D574AE62381A", title: "AGRICULTURAL AND RURAL DEVELOPMENT AUTHORITY ACT [CHAPTER 18:01]" },
    { guid: "58BE3FAB-87CA-449A-B1CD-D9062EC2C010", title: "AGRICULTURAL CORPORATIONS (COMMERCIALIZATION) ACT (ACT 13 of 1997)" },
    { guid: "E451115B-B9D5-4E2C-BE04-37295B20C2E2", title: "AGRICULTURAL FINANCE ACT [CHAPTER 18:02]" },
    { guid: "D59E9F5F-02E5-496E-8D88-6B76435ABCEB", title: "AGRICULTURAL INDUSTRY (EMPLOYEES PENSION SCHEME) ACT [CHAPTER 18:03]" },
    { guid: "DFF7E092-C496-4FD3-9C43-58CF0F2DC752", title: "AGRICULTURAL LAND SETTLEMENT ACT [CHAPTER 20:01]" },
    { guid: "0AF8CD85-EE57-43A1-84C6-CF825ED856D5", title: "AGRICULTURAL MARKETING AUTHORITY ACT [CHAPTER 18:24]" },
    { guid: "BE3E5021-7838-41A0-A8E8-E4D55440951C", title: "AGRICULTURAL MARKETING AUTHORITY ACT [CHAPTER 18:04]" },
    { guid: "23CC782D-FDDF-442A-9ADE-67C730301A7C", title: "AGRICULTURAL PRODUCTS MARKETING ACT [CHAPTER 18:22]" },
    { guid: "DEDD8940-83E7-4129-B28C-7AB5EF51D15B", title: "AGRICULTURAL RESEARCH ACT [CHAPTER 18:05]" },
    { guid: "6A2224AF-E11B-41AD-A6FE-D435E2649FA7", title: "AIR SERVICES ACT [CHAPTER 13:01]" },
    { guid: "D9BF9E3D-52CD-4F48-8A65-F9FF7BB9F568", title: "AIR ZIMBABWE CORPORATION (REPEAL) ACT (ACT 04 of 1998)" },
    { guid: "DA1043DC-2092-4DC6-81AD-74633DA0F5FF", title: "AIR ZIMBABWE CORPORATION ACT [CHAPTER 13:02]" },
    { guid: "5CE1A052-D2AD-4BFB-8C8A-ED3C20D952C4", title: "AIRCRAFT (OFFENCES) ACT [CHAPTER 9:01]" },
    { guid: "1423E61D-B1D7-4FF0-B115-E0055248F192", title: "ALIENATED LAND (INFORMATION) ACT [CHAPTER 20:02]" },
    { guid: "B56BFB33-9847-4F66-8E19-35BB7E440E9C", title: "AMNESTY (GENERAL PARDON) ACT [CHAPTER 9:03]" },
    { guid: "F7DDD445-4C26-44D9-90AE-1D90452E941C", title: "AMNESTY ACT [CHAPTER 9:02]" },
    { guid: "8BA61FAF-A3F8-4CE4-90A5-7A3BD23C9919", title: "ANATOMICAL DONATIONS AND POST-MORTEM EXAMINATIONS ACT [CHAPTER 15:01]" },
    { guid: "FB0CEE2D-2446-48B1-A0C0-D45C3D3FFFDD", title: "ANIMAL HEALTH ACT [CHAPTER 19:01]" },
    { guid: "AE78401F-C9A1-458E-91CF-4C614AE6412B", title: "ANTENUPTIAL CONTRACTS ACT [CHAPTER 5:01]" },
    { guid: "30676E0D-7FD0-4F7C-8F82-0A8AEA698004", title: "ANTI-CORRUPTION COMMISSION ACT [CHAPTER 9:22]" },
    { guid: "8E1ED1C4-367A-46D9-9AF4-4EC90868453E", title: "ANTI-PERSONNEL MINES (PROHIBITION) ACT [CHAPTER 11:19]" },
    { guid: "5313F473-EEF3-4EF6-8407-5CA91F2F84E4", title: "ARBITRATION (INTERNATIONAL INVESTMENT DISPUTES) ACT [CHAPTER 7:03]" },
    { guid: "EFE3D47A-3D66-4816-9146-0C94161AFBE2", title: "ARBITRATION ACT [CHAPTER 7:02]" },
    { guid: "D1197621-454C-43D6-9FE0-7E292D687BEA", title: "ARBITRATION ACT [CHAPTER 7:15]" },
    { guid: "FE42F403-7802-4DFD-B3D7-4081F5DFC25B", title: "ARCHITECTS ACT [CHAPTER 27:01]" },
    { guid: "F6267E5A-C3BB-48D5-AB44-5977E0D3EC5F", title: "ARMORIAL BEARINGS, NAMES, UNIFORMS AND BADGES ACT [CHAPTER 10:01]" },
    { guid: "C097D1CC-FF7E-48F2-9BE3-BDE12FA371ED", title: "ART UNIONS ACT [CHAPTER 25:01]" },
    { guid: "00316228-405A-49D7-9640-418CB836B781", title: "ASSET MANAGEMENT ACT [CHAPTER 24:26]" },
    { guid: "C39AFF8D-113B-4E75-9BF2-989FFFCA2D8B", title: "ATMOSPHERIC POLLUTION PREVENTION ACT [CHAPTER 20:03]" },
    { guid: "1006EAA5-310C-41B5-8151-09C5B3AE2264", title: "ATTORNEY-GENERAL'S OFFICE ACT [CHAPTER 7:19]" },
    { guid: "D11B76EB-F465-4E57-BBB9-53E638FBE6B7", title: "AUDIT AND EXCHEQUER ACT [CHAPTER 22:03]" },
    { guid: "A59B88FF-7BAC-4BBB-80A5-1CBE82D25277", title: "AUDIT OFFICE ACT [CHAPTER 22:18]" },
    { guid: "E991CC3E-E7ED-42EF-A656-59003E35E115", title: "AVIATION ACT [CHAPTER 13:03]" },
    { guid: "8C099476-6373-41CC-9D6E-EAA946924DB3", title: "BALANCE OF PAYMENTS REPORTING ACT [CHAPTER 22:16]" },
    { guid: "05424483-67FA-4861-A1CB-F8D02671E92C", title: "BANK USE PROMOTION ACT [CHAPTER 24:24]" },
    { guid: "872BDCDE-C7BD-4EBE-B3DA-A9199F5F5D84", title: "BANKING ACT [CHAPTER 24:20]" },
    { guid: "D6CD4FC3-316A-4494-BD3F-279DB8A9417F", title: "BANKING ACT [CHAPTER 24:01]" },
    { guid: "F5E07A4E-0B6B-4072-95BB-68FDF99B7F40", title: "BASE MINERALS EXPORT CONTROL ACT [CHAPTER 21:01]" },
    { guid: "32A43652-085A-4AA8-A701-BF8542DE6B35", title: "BEES ACT [CHAPTER 19:02]" },
    { guid: "CD8D744E-71CA-4799-9BC1-F602B1FC8A90", title: "BETTING AND TOTALIZATOR CONTROL ACT [CHAPTER 10:02]" },
    { guid: "A5786255-2F03-4946-B941-BA2846E6EFF8", title: "BILLS OF EXCHANGE ACT [CHAPTER 14:02]" },
    { guid: "F39729E8-9738-4AE3-8994-262A663978E1", title: "BINDURA UNIVERSITY OF SCIENCE EDUCATION ACT [CHAPTER 25:22]" },
    { guid: "32DACA3F-D92E-4382-B72F-32AE5450E9CB", title: "BIRTHS AND DEATHS REGISTRATION ACT [CHAPTER 5:02]" },
    { guid: "2DB99CC2-6E07-4CBB-B252-897DCE6F96CB", title: "BONUS PROHIBITION ACT [CHAPTER 14:03]" },
    { guid: "22F8856F-0B58-46E8-8090-F4D28AF81699", title: "BOXING AND WRESTLING CONTROL ACT [CHAPTER 25:02]" },
    { guid: "A7E82E7E-ED8F-48FC-8255-807E7CAB3A8D", title: "BOY SCOUTS ASSOCIATION ACT [CHAPTER 25:03]" },
    { guid: "52FE3FED-6F8A-44A7-976D-D9DEFF522080", title: "BRANDS ACT [CHAPTER 19:03]" },
    { guid: "141CCBEF-9521-4393-9934-2B2ECD7C8DC9", title: "BROADCASTING SERVICES ACT [CHAPTER 12:06]" },
    { guid: "8E313437-17E3-4BE7-85CD-A7A659D09EAD", title: "BUILDING SOCIETIES ACT [CHAPTER 24:02]" },
    { guid: "8EAB52A8-B561-4A8E-8A7A-37B24F75DC9F", title: "BURIAL AND CREMATION ACT [CHAPTER 5:03]" },
    { guid: "266C605F-6342-4759-AA89-F37E33E1B76F", title: "CAPITAL GAINS TAX ACT [CHAPTER 23:01]" },
    { guid: "E79EA673-D277-46C1-AA97-D065E0EF96F3", title: "CARRIAGE BY AIR ACT [CHAPTER 13:04]" },
    { guid: "E9B14C0C-0A43-4E97-A6CB-2C552A3F4A5D", title: "CASINO ACT [CHAPTER 10:03]" },
    { guid: "3C0598FA-203D-4F02-B3D0-BAE8D78B3E3E", title: "CEMETERIES ACT [CHAPTER 5:04]" },
    { guid: "05EAE27A-4AC9-4D1D-9014-6ABE3B2A45ED", title: "CENSORSHIP AND ENTERTAINMENTS CONTROL ACT [CHAPTER 10:04]" },
    { guid: "847ABEB9-BB2D-489C-B85B-025F4FFD464E", title: "CENSUS AND STATISTICS ACT [CHAPTER 10:05]" },
    { guid: "84D59F86-276F-4E95-89CC-6B0E8D32AE66", title: "CENSUS AND STATISTICS ACT [CHAPTER 10:29]" },
    { guid: "047ACE18-8DD6-472D-AD42-21BF02D33875", title: "CHAMBER OF MINES OF ZIMBABWE INCORPORATION (PRIVATE) ACT [CHAPTER 21:02]" },
    { guid: "DEAC5046-B43E-4205-97C1-8993FF6311A6", title: "CHARTERED ACCOUNTANTS ACT [CHAPTER 27:02]" },
    { guid: "7C924B4C-D848-4B38-A162-AC3ABCD024ED", title: "CHARTERED SECRETARIES (PRIVATE) ACT [CHAPTER 27:03]" },
    { guid: "4E557DE7-A7CC-4D22-839E-5928A7385BE1", title: "CHEMICAL WEAPONS (PROHIBITION) ACT [CHAPTER 11:18]" },
    { guid: "5E50A751-EC49-42BA-ACB9-0C070D5E3A86", title: "CHIEFS AND HEADMEN ACT [CHAPTER 29:01]" },
    { guid: "7B8BCCFC-80C5-4639-830E-FA86032393F6", title: "CHILD ABDUCTION ACT [CHAPTER 5:05]" },
    { guid: "AFDF9957-BE49-45A4-AFBE-D7FF9DF9B12D", title: "CHILDREN’S ACT [CHAPTER 5:06]" },
    { guid: "EA3374EC-6E9A-43B2-BCC6-B2F52ABAF5DA", title: "CHINHOYI UNIVERSITY OF TECHNOLOGY ACT [CHAPTER 25:23]" },
    { guid: "08DB2EAB-B374-4387-8628-50DC0725081C", title: "CHIROPRACTORS ACT [CHAPTER 27:04]" },
    { guid: "0DBC1D7B-8D35-40D5-910F-A6FDE9959E8B", title: "CITIZENSHIP OF ZIMBABWE ACT [CHAPTER 4:01]" },
    { guid: "8358A2E9-6D9B-4E6D-A962-257C0D655F7F", title: "CITY OF BULAWAYO (PRIVATE) ACT [CHAPTER 29:02]" },
    { guid: "DD4C116A-3EC9-4295-BAC5-443ABA20BDF9", title: "CITY OF GWERU (PRIVATE) ACT [CHAPTER 29:03]" },
    { guid: "03592FA6-0D7B-4EA1-BE26-C7984641EEB6", title: "CITY OF HARARE (PRIVATE) ACT [CHAPTER 29:04]" },
    { guid: "DC5144D0-6134-4E32-A999-848F955B2FD2", title: "CITY OF MUTARE (PRIVATE) ACT [CHAPTER 29:05]" },
    { guid: "D080E45D-D7F6-4DC4-B82D-2C8A10C157ED", title: "CIVIL AVIATION ACT [CHAPTER 13:16]" },
    { guid: "74CBB65A-9929-4A82-826A-03BD7E631E4C", title: "CIVIL EVIDENCE ACT [CHAPTER 8:01]" },
    { guid: "4D056082-A687-4F66-AC99-BEDCFAAA83B1", title: "CIVIL MATTERS (MUTUAL ASSISTANCE) ACT [CHAPTER 8:02]" },
    { guid: "C9265C9B-F86D-4FEA-AD1E-A8F5BDD7AA10", title: "CIVIL PROTECTION ACT [CHAPTER 10:06]" },
    { guid: "90D57184-B13D-4B3D-89F9-72A5240EF43E", title: "CIVIL SERVICE ACT [CHAPTER 16:04]" },
    { guid: "C8BFB38E-C482-4D10-A225-E5B5204629FC", title: "CLASS ACTIONS ACT [CHAPTER 8:17]" },
    { guid: "8569AC0F-E641-42FE-A330-F053B173FC22", title: "COLD STORAGE COMMISSION ACT [CHAPTER 18:06]" },
    { guid: "77AAD292-E85B-4B09-AC72-F430FA8693E0", title: "COLLECTIVE INVESTMENT SCHEMES ACT [CHAPTER 24:19]" },
    { guid: "0C260522-8162-49F6-8CD7-7F3FFF2CC568", title: "COMMERCIAL PREMISES (LEASE CONTROL) ACT [CHAPTER 14:04]" },
    { guid: "029295BE-3EC6-4486-935B-EA181D12EA29", title: "COMMISSIONS OF INQUIRY ACT [CHAPTER 10:07]" },
    { guid: "6CEE5853-691C-42FB-8B8D-F2D2B656763C", title: "COMMONWEALTH FORCES (JURISDICTION) ACT [CHAPTER 11:01]" },
    { guid: "78E4379E-C1A3-4D60-BF1D-3F984507AB46", title: "COMMUNAL LAND ACT [CHAPTER 20:04]" },
    { guid: "61ED0E45-1422-4126-9DC8-624AE76193FD", title: "COMMUNAL LAND FOREST PRODUCE ACT [CHAPTER 19:04]" },
    { guid: "045C5244-D73D-425A-B1B9-17C66D0B7FCA", title: "COMPANIES ACT [CHAPTER 24:03]" },
    { guid: "1CB7E9C2-1644-4748-9006-09DC44ADE469", title: "COMPANIES AND ASSOCIATIONS TRUSTEES ACT [CHAPTER 24:04]" },
    { guid: "A32BE4CA-C0DE-4EC3-9A9C-68DA29D5FEB9", title: "COMPANIES AND OTHER BUSINESS ENTITIES ACT [CHAPTER 24:31]" },
    { guid: "74E70D35-7616-4172-BCF9-C55FF8B3B84C", title: "COMPETITION ACT [CHAPTER 14:28]" },
    { guid: "A31C9803-A316-4D6A-98C7-EC2A90B800B9", title: "CONCEALMENT OF BIRTH ACT [CHAPTER 9:04]" },
    { guid: "10a50f59-ebcd-444e-a2eb-dab751b8b68d", title: "CONSTITUTIONAL COURT ACT [CHAPTER 7:22]" },
    { guid: "53B96275-4EBD-41D9-89D5-D130122D82D1", title: "CONSUMER CONTRACTS ACT [CHAPTER 8:03]" },
    { guid: "44589C1A-A0FB-4C8E-90C9-63C037853AB9", title: "CONSUMER PROTECTION ACT [CHAPTER 14:37]" },
    { guid: "49C25009-0DF6-4E20-8700-00EF1EF55C1B", title: "CONTRACTUAL PENALTIES ACT [CHAPTER 8:04]" },
    { guid: "3B4E2289-86AF-4BC1-9F1E-EEFC618D90B8", title: "CONTROL OF GOODS ACT [CHAPTER 14:05]" },
    { guid: "29877483-8D59-44D7-B368-5290F9FDF54F", title: "CO-OPERATIVE SOCIETIES ACT [CHAPTER 24:05]" },
    { guid: "4045873A-2A6C-4F4F-AE59-38611EC7181A", title: "COPPER CONTROL ACT [CHAPTER 14:06]" },
    { guid: "0E22746B-04A5-465D-A756-C3511EA23ACF", title: "COPYRIGHT AND NEIGHBOURING RIGHTS ACT [CHAPTER 26:05]" },
    { guid: "9F547CE9-74AC-4CD1-B197-EEBE8245D2E8", title: "COPYRIGHT ACT [CHAPTER 26:01]" },
    { guid: "F3CA5223-BFD2-4F16-86BA-D3CA5ED6BB09", title: "CORONER’S OFFICE ACT  [CHAPTER 7:21]" },
    { guid: "2048ACEE-FF92-49B4-8A6A-3CD9494109C9", title: "COTTON MARKETING AND CONTROL ACT [CHAPTER 18:07]" },
    { guid: "05650828-F255-435A-8802-FC6742A24F12", title: "COURTS AND ADJUDICATING AUTHORITIES (PUBLICITY RESTRICTION) ACT [CHAPTER 7:04]" },
    { guid: "2E5C394B-62E4-46C8-BC25-4CCF2D245A18", title: "CRIMINAL LAW (CODIFICATION AND REFORM) ACT [CHAPTER 9:23]" },
    { guid: "E920E8E8-B946-4C9C-ACDC-DB925B6EE2DC", title: "CRIMINAL LAW AMENDMENT ACT [CHAPTER 9:05]" },
    { guid: "AF4EC710-73D0-4A45-A970-BFEE3C031A48", title: "CRIMINAL MATTERS (MUTUAL ASSISTANCE) ACT [CHAPTER 9:06]" },
    { guid: "71C9088E-825D-4FFB-B4B8-F734666FA68D", title: "CRIMINAL PROCEDURE AND EVIDENCE ACT [CHAPTER 9:07]" },
    { guid: "42B64F1F-A677-4A80-AB5B-083CC0A56DA2", title: "CUSTOMARY LAW (APPLICATION) ACT [CHAPTER 8:05]" },
    { guid: "3F580711-5DB4-417D-91ED-459BEB48DBAB", title: "CUSTOMARY LAW AND LOCAL COURTS ACT [CHAPTER 7:05]" },
    { guid: "138A73D0-A971-4FBE-8A38-DD5DB1BE3442", title: "CUSTOMARY MARRIAGES ACT [CHAPTER 5:07]" },
    { guid: "02D03EE2-BDD2-412F-808E-C95EECB379E7", title: "CUSTOMS AND EXCISE ACT [CHAPTER 23:02]" },
    { guid: "5c5d72c6-78e0-4e73-97b8-cffbff1ab2ff", title: "CYBER AND DATA PROTECTION ACT [CHAPTER 12:07]" },
    { guid: "EFB36D47-9DE2-499C-B686-20050E9AD929", title: "DAIRY ACT [CHAPTER 18:08]" },
    { guid: "0B575F24-D2D0-4BCC-A149-FB7B9DEE9833", title: "DAIRY MARKETING BOARD (DEBT ASSUMPTION) ACT (ACT 15 of 1997)" },
    { guid: "5FE0A629-40A7-4551-A9B7-9DCB63786012", title: "DAIRY PRODUCE MARKETING AND LEVY ACT [CHAPTER 18:09]" },
    { guid: "2CAED0FF-6512-442D-8B7B-D9D49D5157B7", title: "DAMAGES (APPORTIONMENT AND ASSESSMENT) ACT [CHAPTER 8:06]" },
    { guid: "41E46CAE-4E58-45EA-A800-C794B9C7B2BE", title: "DANGEROUS DRUGS ACT [CHAPTER 15:02]" },
    { guid: "5AD1D87A-6F1F-4800-B414-7851A29D4EB6", title: "DECEASED ESTATES SUCCESSION ACT [CHAPTER 6:02]" },
    { guid: "CC6E379F-61AE-4132-8C71-75B733C53753", title: "DECEASED PERSONS FAMILY MAINTENANCE ACT [CHAPTER 6:03]" },
    { guid: "7138FF23-E720-47AC-B41B-8D9DFA299B03", title: "DECIMAL CURRENCY ACT [CHAPTER 22:04]" },
    { guid: "045D793B-D684-4FE4-96C7-AC8AA64D05C9", title: "DEEDS REGISTRIES ACT [CHAPTER 20:05]" },
    { guid: "166AF3C0-CFD7-4135-94AC-305793716433", title: "DEFENCE ACT [CHAPTER 11:02]" },
    { guid: "4F0BB3B1-9A5F-4FE1-907B-DC0B7137D859", title: "DEFENCE PROCUREMENT ACT [CHAPTER 11:03]" },
    { guid: "2401FEEA-DBD9-41BB-9916-69D12514B318", title: "DEPARTMENT OF PRINTING AND STATIONERY (COMMERCIALISATION) ACT (ACT 13 of 1999)" },
    { guid: "A12C4D27-E50D-4CDA-80EF-593EE5DE260C", title: "DEPOSIT PROTECTION CORPORATION ACT [CHAPTER 24:29]" },
    { guid: "D7F8898C-13FE-43DF-B1F2-08E43A6ACE91", title: "DISABLED PERSONS ACT [CHAPTER 17:01]" },
    { guid: "BC2B2FBD-F50F-4ED0-A7ED-A3863FE0E240", title: "DISTRICT DEVELOPMENT FUND ACT [CHAPTER 29:06]" },
    { guid: "2C733A09-284A-462C-8748-5E5F33BEB683", title: "DOMESTIC VIOLENCE ACT [CHAPTER 5:16]" },
    { guid: "EB11DAC8-44D0-4F19-85E3-091F055D10AD", title: "EDUCATION ACT [CHAPTER 25:04]" },
    { guid: "5bc80ef7-f2e8-4232-b3ce-be6ff0a133c9", title: "EDUCATION, INNOVATION, RESEARCH AND DEVELOPMENT CENTRE ACT [CHAPTER 25:34]" },
    { guid: "A2EF4D00-C317-4DEB-B94B-CBF9FB14C7DC", title: "ELECTORAL ACT [CHAPTER 2:01]" },
    { guid: "C10BB40B-2C17-48E1-97E1-DDF9148B388F", title: "ELECTORAL ACT [CHAPTER 2:13]" },
    { guid: "DF09F269-B47C-450E-96A6-B7D5D7F1A119", title: "ELECTRICITY ACT [CHAPTER 13:19]" },
    { guid: "D9A93415-504E-4D3B-97D7-88261E8E3D78", title: "ELECTRICITY ACT [CHAPTER 13:05]" },
    { guid: "F4023B44-440D-4A74-BE46-EFFA950D88D2", title: "EMERGENCY POWERS ACT [CHAPTER 11:04]" },
    { guid: "AA0638A5-9411-4ADA-9556-249D3430CA35", title: "ENERGY REGULATORY AUTHORITY ACT [CHAPTER 13:23]" },
    { guid: "7E97D601-D4E3-4B68-A58D-CCB8269D27A8", title: "ENGINEERING COUNCIL ACT [CHAPTER 27:22]" },
    { guid: "2151F34D-C885-44A3-BB74-35F0BBB61F69", title: "ENVIRONMENTAL MANAGEMENT ACT [CHAPTER 20:27]" },
    { guid: "13C26393-AE43-4ECC-90AF-6709B0B3F0B5", title: "ESTATE ADMINISTRATORS AND INSOLVENCY PRACTITIONERS ACT [CHAPTER 27:20]" },
    { guid: "E0A2E50D-2CC7-44D0-8E46-48F93DCF5892", title: "ESTATE AGENTS ACT [CHAPTER 27:17]" },
    { guid: "042A92A6-EC46-48E7-9A09-CC5D0162A9CF", title: "ESTATE AGENTS ACT [CHAPTER 27:05]" },
    { guid: "766634B4-1D0B-477E-8768-6433B28D9A48", title: "ESTATE DUTY ACT [CHAPTER 23:03]" },
    { guid: "E5D533EE-F943-4828-936E-1E40B16CD691", title: "EXCHANGE CONTROL ACT [CHAPTER 22:05]" },
    { guid: "AF9D8AEE-78B9-4240-BACC-875CC3CCFBA5", title: "EXPLOSIVES ACT [CHAPTER 10:08]" },
    { guid: "5B412754-B98B-4AB1-A096-C69C6C987B12", title: "EX-POLITICAL PRISONERS, DETAINEES AND RESTRICTEES ACT [CHAPTER 17:10]" },
    { guid: "3D22C7C5-6184-40F7-9B79-71F4389911AF", title: "EXPORT CREDIT REINSURANCE ACT [CHAPTER 24:06]" },
    { guid: "A1DA7164-631F-4E0F-BFC9-92F4622F577B", title: "EXPORT PROCESSING ZONES ACT [CHAPTER 14:07]" },
    { guid: "FB29B68C-EA06-4E2F-8949-AFB8AFAD2A49", title: "EXTRADITION ACT [CHAPTER 9:08]" },
    { guid: "AB5D7E17-E73F-44BC-BC86-210A5B5A187F", title: "FACTORIES AND WORKS ACT [CHAPTER 14:08]" },
    { guid: "D5EC124C-FCA4-4C90-BE20-1C913AE07843", title: "FARMERS LICENSING AND LEVY ACT [CHAPTER 18:10]" },
    { guid: "DF94CAB1-D1B9-4DD4-84A3-DF5830AA4E26", title: "FARMERS STOP-ORDER ACT [CHAPTER 18:11]" },
    { guid: "7261B3C1-75DD-4B16-9E0A-2E11560C18E7", title: "FENCING ACT [CHAPTER 20:06]" },
    { guid: "4AE0A5C4-3E3C-43A8-8581-2D6B89565D2C", title: "FERTILIZERS, FARM FEEDS AND REMEDIES ACT [CHAPTER 18:12]" },
    { guid: "8672D46D-AF4E-430B-BEE8-A58689EEDC24", title: "FINANCE ACT [CHAPTER 23:04]" },
    { guid: "5B49B23E-8995-4395-B658-DD618C4A736A", title: "FINGO LOCATION (DISTRIBUTION OF LAND) ACT [CHAPTER 20:07]" },
    { guid: "DCBA5A09-3C64-4D5A-880A-1B7E163E494A", title: "FIREARMS ACT [CHAPTER 10:09]" },
    { guid: "D0B091E0-77B1-416F-8E9F-A11D6C46774A", title: "FISCAL APPEAL COURT ACT [CHAPTER 23:05]" },
    { guid: "C245EE85-E31A-4BCD-9CF1-0D1EEC83DD9B", title: "FLAG OF ZIMBABWE ACT [CHAPTER 10:10]" },
    { guid: "F5E3F6C0-D92F-419A-ADAA-4DE2859B3DB4", title: "FOOD AND FOOD STANDARDS ACT [CHAPTER 15:04]" },
    { guid: "48B908A5-8E3E-4696-BB40-3B89480116E0", title: "FOREIGN MISSIONS AND AGENCIES (PREMISES) ACT [CHAPTER 3:01]" },
    { guid: "7B73AB66-E76B-4D28-8546-71DCD5537F0C", title: "FOREIGN REPRESENTATIVES PARKING PRIVILEGES ACT [CHAPTER 3:02]" },
    { guid: "098F2552-D5BA-40F7-A139-E0E5921DAA2E", title: "FOREIGN SUBVERSIVE ORGANIZATIONS ACT [CHAPTER 11:05]" },
    { guid: "08E012FF-B657-4DA8-BED2-EAC54335A67A", title: "FOREST ACT [CHAPTER 19:05]" },
    { guid: "885F9B60-4326-4FD9-8657-16179B124147", title: "FORMER ADMINISTRATION (LIABILITIES) ACT [CHAPTER 22:06]" },
    { guid: "3F51E379-F8BD-46FC-BBB4-183DE276889E", title: "FREDERICK CLAYTON TRUST ACT [CHAPTER 17:02]" },
    { guid: "7c44bb63-28a4-4826-bf98-97f94ffa07b5", title: "FREEDOM OF INFORMATION ACT [CHAPTER 10:34]" },
    { guid: "E1E99388-23BF-4965-8E3B-0FE51EE999A3", title: "FRUIT MARKETING ACT [CHAPTER 18:13]" },
    { guid: "A07C62A4-AC4F-4F61-952F-455F79657700", title: "GAMING-HOUSES SUPPRESSION ACT [CHAPTER 9:09]" },
    { guid: "0D023322-6242-41B2-83CF-64A1A68A3FDA", title: "GAZETTED LAND (CONSEQUENTIAL PROVISIONS) ACT [CHAPTER 20:28]" },
    { guid: "5D2D1329-B030-423D-BF36-16DFC93A98B0", title: "GENERAL LAW AMENDMENT ACT [CHAPTER 8:07]" },
    { guid: "08798138-EB67-4EA7-9464-CCE9EB1762AA", title: "GENEVA CONVENTIONS ACT [CHAPTER 11:06]" },
    { guid: "C3E029B0-3269-4AD3-B274-9522C8AF2C0E", title: "GENOCIDE ACT [CHAPTER 9:20]" },
    { guid: "42466357-02ED-4C42-B149-184D5317027C", title: "GEOGRAPHICAL INDICATIONS ACT [CHAPTER 26:06]" },
    { guid: "F65E8AA7-822B-4DFE-8D62-9C3D887B9416", title: "GOLD TRADE ACT [CHAPTER 21:03]" },
    { guid: "0A496D89-9C6E-4AA2-ADB0-596502C471BB", title: "GRAIN MARKETING ACT [CHAPTER 18:14]" },
    { guid: "8FEE1F46-180C-4949-B57F-692C4D8F0F02", title: "GREAT ZIMBABWE UNIVERSITY ACT [CHAPTER 25:24]" },
    { guid: "27C45C53-DD2E-494C-ABF2-B3970BA76586", title: "GUARDIANSHIP OF MINORS ACT [CHAPTER 5:08]" },
    { guid: "E629C9BC-B54E-4791-A647-56E41E721F93", title: "GWANDA STATE UNIVERSITY ACT [CHAPTER 25:30]" },
    { guid: "0FF46390-7DBE-4CAC-A1B2-F7A332F24637", title: "HARARE CITY BUILDING (PRIVATE) ACT [CHAPTER 29:07]" },
    { guid: "DBEAA5C3-1696-44EB-A3E2-0FB6B6F11FC7", title: "HARARE CITY LIBRARY ACT [CHAPTER 25:05]" },
    { guid: "57EB2FEB-B378-4CAF-A36E-F03736D448C9", title: "HARARE INSTITUTE OF TECHNOLOGY ACT [CHAPTER 25:26]" },
    { guid: "2F9DF07D-DDB4-4B8C-B33A-9DD9400761AE", title: "HARMFUL LIQUIDS ACT [CHAPTER 9:10]" },
    { guid: "C990E819-0708-4B70-B34E-7D1C7958CDEB", title: "HAZARDOUS SUBSTANCES AND ARTICLES ACT [CHAPTER 15:05]" },
    { guid: "33FF64F9-AC5A-42BB-BAF1-C6EE9982FAEA", title: "HEALTH PROFESSIONS ACT [CHAPTER 27:19]" },
    { guid: "5F3163AC-BA2B-4125-A93F-D7D7D80EFA62", title: "HEALTH SERVICE ACT [CHAPTER 15:16]" },
    { guid: "FB7CC6A8-70A4-440F-A8A2-977C575B956E", title: "HIGH COURT ACT [CHAPTER 7:06]" },
    { guid: "3B176E92-FFC6-4CC6-A8E6-267F9CD2232B", title: "HIPPO VALLEY AGREEMENT ACT [CHAPTER 20:08]" },
    { guid: "DA3A103F-8BA3-4F52-A95E-FF7E7022C591", title: "HIRE-PURCHASE ACT [CHAPTER 14:09]" },
    { guid: "55D3E9F8-89D2-4F69-952A-C736E2438382", title: "HONOURS AND AWARDS ACT [CHAPTER 10:11]" },
    { guid: "48C62964-309F-4D02-B044-E1A9EBA22E50", title: "HOUSING AND BUILDING ACT [CHAPTER 22:07]" },
    { guid: "7E6FA42B-9F8F-42CE-88EC-918E8B5A8250", title: "HOUSING STANDARDS CONTROL ACT [CHAPTER 29:08]" },
    { guid: "E648FF65-3F1B-4CE5-8149-FCEF16D8CEAF", title: "IMMIGRATION ACT [CHAPTER 4:02]" },
    { guid: "2421385C-2444-418A-8952-A02FBD844EAC", title: "IMMOVABLE PROPERTY (PREVENTION OF DISCRIMINATION) ACT [CHAPTER 10:12]" },
    { guid: "C770C606-ECC7-4F79-9DDA-5F3A8519000E", title: "INCOME TAX (TRANSITIONAL PERIOD PROVISIONS) ACT [CHAPTER 23:07]" },
    { guid: "2ABA307A-2AE9-49C7-BA55-191AE56CD5B1", title: "INCOME TAX ACT [CHAPTER 23:13]" },
    { guid: "6727DE2F-8465-453E-A7BE-28F17DD11FA0", title: "INCOME TAX ACT [CHAPTER 23:06]" },
    { guid: "1E15EF14-C298-4E92-BC4E-174C5F5BE979", title: "INDIGENISATION AND ECONOMIC EMPOWERMENT ACT [CHAPTER 14:33]" },
    { guid: "7DD4698C-00DD-4842-B032-4731357B72EE", title: "INDUSTRIAL DESIGNS ACT [CHAPTER 26:02]" },
    { guid: "FCFE2AD7-C431-405A-9310-D1D8A5C9A6D8", title: "INDUSTRIAL DEVELOPMENT CORPORATION ACT [CHAPTER 14:10]" },
    { guid: "FD941591-1C13-48B4-9A17-A3D4EE013359", title: "INEBRIATES ACT [CHAPTER 9:11]" },
    { guid: "DD02533D-9847-4E48-B28B-F3E5D3AB0AF1", title: "INFANTICIDE ACT [CHAPTER 9:12]" },
    { guid: "D78101AC-D687-4678-BE9F-978A45BDEE64", title: "INFRASTRUCTURE DEVELOPMENT BANK OF ZIMBABWE ACT [CHAPTER 24:14]" },
    { guid: "C5132DB3-8075-46F4-A639-B0ACC6FD9AEC", title: "INLAND WATERS SHIPPING ACT [CHAPTER 13:06]" },
    { guid: "0841A7F1-249F-40F7-9588-63E7994562FE", title: "INNER BANK TITLES ACT [CHAPTER 20:09]" },
    { guid: "D2476F38-57C3-47B9-8D94-12A4DAE04307", title: "INQUESTS ACT [CHAPTER 7:07]" },
    { guid: "9B539214-6627-4149-B9A8-51A7D5D0E285", title: "INSOLVENCY ACT [CHAPTER 6:04]" },
    { guid: "7E5A6C08-FF23-491B-ADE2-E35C689061F1", title: "INSOLVENCY ACT [CHAPTER 6:07]" },
    { guid: "A0204A50-EA0A-4139-B942-B2C1ADEFF4A6", title: "INSURANCE ACT [CHAPTER 24:07]" },
    { guid: "67532F5E-09E9-4A4B-BA09-B218B4A1E2FB", title: "INSURANCE AND PENSIONS COMMISSION ACT [CHAPTER 24:21]" },
    { guid: "CBC26E6C-4754-4B00-958C-DD5A59D2099F", title: "INTEGRATED CIRCUIT LAYOUT-DESIGNS ACT [CHAPTER 26:07]" },
    { guid: "85C3582B-DC19-4078-AFBA-3318106407F5", title: "INTELLECTUAL PROPERTY TRIBUNAL ACT [CHAPTER 26:08]" },
    { guid: "B7753B33-48D6-4920-AAED-4D1BD255FCCB", title: "INTERCEPTION OF COMMUNICATIONS ACT [CHAPTER 11:20]" },
    { guid: "65AD0980-F39C-457A-8F77-E541F101A39B", title: "INTERNATIONAL BANK LOANS ASSUMPTION ACT [CHAPTER 22:08]" },
    { guid: "E9A12698-CC7F-4D15-813D-79618F92103A", title: "INTERNATIONAL FINANCIAL ORGANIZATIONS ACT [CHAPTER 22:09]" },
    { guid: "10d6382b-53a3-40c0-9439-b370e31e5055", title: "INTERNATIONAL TREATIES ACT [CHAPTER 3:05]" },
    { guid: "356AD35C-5AE1-4460-92D2-4F036530394C", title: "INTERPRETATION ACT [CHAPTER 1:01]" },
    { guid: "DD0D71F0-0936-45A1-9F06-78CB52EEEB45", title: "IRON AND STEEL INDUSTRY ACT [CHAPTER 14:11]" },
    { guid: "347568F2-D7A7-412F-9368-F2568C68FE57", title: "JOINT VENTURES ACT [CHAPTER 22:22]" },
    { guid: "34CDE61C-1B6B-42A1-86F7-5CF6D8725456", title: "JUDGES SALARIES, ALLOWANCES AND PENSIONS ACT [CHAPTER 7:08]" },
    { guid: "D6AB3940-B61B-47B2-AFFC-E38605B142EE", title: "JUDICIAL COLLEGE ACT [CHAPTER 7:17]" },
    { guid: "BF877C44-1507-4F82-8EEB-B0BDB1CAA2E9", title: "JUDICIAL SERVICE ACT [CHAPTER 7:18]" },
    { guid: "CAFB55F4-7D21-45DB-9663-0688F64D3FA3", title: "JUSTICES OF THE PEACE AND COMMISSIONERS OF OATHS ACT [CHAPTER 7:09]" },
    { guid: "20DEC11C-9CC5-4757-B768-130CC05B7945", title: "LABOUR ACT [CHAPTER 28:01]" },
    { guid: "2C468166-76A9-4EC4-B0F8-221D981270A4", title: "LAND ACQUISITION ACT [CHAPTER 20:10]" },
    { guid: "BEC33261-C186-41B1-9E7E-5FAC709A24A5", title: "LAND COMMISSION ACT  [CHAPTER 20:29]" },
    { guid: "C7C0E80E-EC83-4825-B956-F3529C01593C", title: "LAND OCCUPATION CONDITIONS ACT [CHAPTER 20:11]" },
    { guid: "11DD9696-04BA-4512-A906-3AA3CFE547BC", title: "LAND SURVEY ACT [CHAPTER 20:12]" },
    { guid: "5763EC39-D699-4AA5-B707-90042614CD8E", title: "LAND SURVEYORS ACT [CHAPTER 27:06]" },
    { guid: "CDAAEF75-22F1-458D-956F-B718ECE4B84B", title: "LAW AND ORDER (MAINTENANCE) ACT [CHAPTER 11:07]" },
    { guid: "B7682A9C-2C50-4608-8E1E-A751B1645606", title: "LAW DEVELOPMENT COMMISSION ACT [CHAPTER 1:02]" },
    { guid: "4DA5CDA7-618E-44C1-82D3-843C9A6ECB6E", title: "LEGAL AID ACT [CHAPTER 7:16]" },
    { guid: "7D11C0D0-0993-4561-8F34-18675C9E271A", title: "LEGAL ASSISTANCE AND REPRESENTATION ACT [CHAPTER 9:13]" },
    { guid: "BB25ECD8-772E-49FE-84D5-606AF377CD83", title: "LEGAL PRACTITIONERS ACT [CHAPTER 27:07]" },
    { guid: "604D0E8D-4F7D-4D89-AD0E-D9A8A3CDC3BA", title: "LIMITATION OF LIABILITY (PASSENGERS IN STATE AIRCRAFT) ACT [CHAPTER 8:08]" },
    { guid: "90409047-284B-4BB8-B9DA-B2FC0BEB7D96", title: "LIQUOR ACT [CHAPTER 14:12]" },
    { guid: "25F90207-85D2-4855-89D7-F9B4D032FBDE", title: "LOCAL AUTHORITIES EMPLOYEES (PENSION SCHEMES) ACT [CHAPTER 29:09]" },
    { guid: "3950D153-BA85-471E-BA13-F20C031A5770", title: "LOCUST CONTROL ACT [CHAPTER 19:06]" },
    { guid: "B3C95C86-32F2-42C1-A085-6E217AEC7210", title: "LOTTERIES AND GAMING (EXEMPTION) ACT [CHAPTER 10:13]" },
    { guid: "3354AE0F-4C1F-44DF-9F70-123FC3901727", title: "LOTTERIES AND GAMING ACT [CHAPTER 10:26]" },
    { guid: "E1C4CFE7-900E-44A2-B2A0-6F712970781A", title: "LOTTERIES PROHIBITION ACT [CHAPTER 9:14]" },
    { guid: "01E965AB-F1A1-42D5-BFA6-1A4F27D1E84F", title: "LUPANE STATE UNIVERSITY ACT [CHAPTER 25:25]" },
    { guid: "0BB67B00-53E8-4143-B655-57B9686F2E5F", title: "MAGISTRATES COURT ACT [CHAPTER 7:10]" },
    { guid: "5B4047F9-6E52-44DA-981F-C199B5B8E900", title: "MAINTENANCE ACT [CHAPTER 5:09]" },
    { guid: "2D777134-E113-43F1-8A86-2D3C9E122190", title: "MAINTENANCE OF PEACE AND ORDER ACT [CHAPTER 11:23]" },
    { guid: "5FCFD2F1-2B40-4BB3-9E99-1D32BF117AC7", title: "MAINTENANCE ORDERS (FACILITIES FOR ENFORCEMENT) ACT [CHAPTER 5:10]" },
    { guid: "7EB02981-BCC3-4C28-8392-E7007BD772B3", title: "MANICALAND STATE UNIVERSITY OF APPLIED SCIENCES ACT [CHAPTER 25:31]" },
    { guid: "136E3E22-916F-4C9D-B4C8-AAF7A9C50646", title: "MANPOWER PLANNING AND DEVELOPMENT ACT [CHAPTER 28:02]" },
    { guid: "A4D4CE7D-434B-4249-8447-A16330E627F4", title: "MARONDERA UNIVERSITY OF AGRICULTURAL, SCIENCES AND TECHNOLOGY ACT [CHAPTER 25:29]" },
    { guid: "727756F2-EDB2-46AB-B852-8BB29EB9534D", title: "MARRIAGE ACT [CHAPTER 5:11]" },
    { guid: "6B4FA77E-2D61-4971-9DEB-4C0DA1DE30EE", title: "MARRIED PERSONS PROPERTY ACT [CHAPTER 5:12]" },
    { guid: "C92317BF-1B51-492B-917A-CB8037BD404A", title: "MASONIC EDUCATION FUND INCORPORATION ACT [CHAPTER 17:03]" },
    { guid: "F6B29F17-9E70-4F6B-9C84-CB37BC21402F", title: "MATRIMONIAL CAUSES ACT [CHAPTER 5:13]" },
    { guid: "E5FC8503-16F0-4FC8-8AE2-DB2649CADB0F", title: "MEDICAL SERVICES ACT [CHAPTER 15:13]" },
    { guid: "D2DC6CB4-94A6-4BE8-A550-6F61FD0BB9DB", title: "MEDICAL, DENTAL AND ALLIED PROFESSIONS ACT [CHAPTER 27:08]" },
    { guid: "80982416-768F-41A4-8B1B-8AB164FADC00", title: "MEDICINES AND ALLIED SUBSTANCES CONTROL ACT [CHAPTER 15:03]" },
    { guid: "A0585752-D2B0-4AC7-8128-7F63773E289D", title: "MENTAL HEALTH ACT [CHAPTER 15:06]" },
    { guid: "2F4E2307-67D4-4083-A816-22F8D812C986", title: "MENTAL HEALTH ACT [CHAPTER 15:12]" },
    { guid: "8489A1EE-8295-418E-9E78-96F93EF759ED", title: "MERCHANDISE MARKS ACT [CHAPTER 14:13]" },
    { guid: "92F07B72-BE19-499D-B503-8106187BEB79", title: "METEOROLOGICAL SERVICES ACT [CHAPTER 13:21]" },
    { guid: "121EBAE6-D6A3-40EB-ADC5-0A351AC1D884", title: "MICROFINANCE ACT [CHAPTER 24:30]" },
    { guid: "A776360B-F39F-4556-A46E-F824695C535A", title: "MIDLANDS STATE UNIVERSITY ACT [CHAPTER 25:21]" },
    { guid: "35D5C1C0-51EF-439A-A4E1-4ED67885DB3D", title: "MINERALS MARKETING CORPORATION OF ZIMBABWE ACT [CHAPTER 21:04]" },
    { guid: "ACFB2FA5-3C5C-40F3-9338-901990D957AB", title: "MINES AND MINERALS ACT [CHAPTER 21:05]" },
    { guid: "5D40DB6A-1A4C-4111-B429-C7F5FE815117", title: "MINES AND MINERALS ACT NOTICE OF PROPOSED EXERCISE BY MINISTER OF HIS POWERS UNDER SECTION 400(1) OF ACT2003" },
    { guid: "06A9664F-B5E8-47AE-B4EF-0C85C04899B3", title: "MISCELLANEOUS OFFENCES ACT [CHAPTER 9:15]" },
    { guid: "A28A2F68-3833-4D37-AD66-A3C140EBCAED", title: "MISSING PERSONS ACT [CHAPTER 5:14]" },
    { guid: "EA4486CE-3779-4215-B2C9-941919B26AC0", title: "MONEY LAUNDERING AND PROCEEDS OF CRIME ACT [CHAPTER 9:24]" },
    { guid: "FB9DC64D-E3EC-4E94-886C-BBCC66A527A5", title: "MONEYLENDING AND RATES OF INTEREST ACT [CHAPTER 14:14]" },
    { guid: "1C7F23A7-20D4-450A-940E-14CD3D31326C", title: "MOVABLE PROPERTY SECURITY INTERESTS ACT [CHAPTER 14:35]" },
    { guid: "E913BD47-1EC6-4A0C-8AB8-1390D88FAF47", title: "MOZAMBIQUE-FERUKA PIPELINE ACT [CHAPTER 13:07]" },
    { guid: "135C85AC-0298-44E9-9883-5EC01F9A67E0", title: "MUNICIPAL TRAFFIC LAWS ENFORCEMENT ACT [CHAPTER 29:10]" },
    { guid: "A175DADC-5992-44C6-B5A5-4E47D7DCFA7C", title: "NAMES (ALTERATION) ACT [CHAPTER 10:14]" },
    { guid: "F4ECF813-010D-4B8B-B198-8976AED2E863", title: "NATIONAL AIDS COUNCIL OF ZIMBABWE ACT [CHAPTER 15:14]" },
    { guid: "4BEE1891-0F9B-4D2B-8A62-FF9F4B40FAAC", title: "NATIONAL ANTHEM OF ZIMBABWE ACT [CHAPTER 10:15]" },
    { guid: "251A4142-BC3A-48CD-9142-1A810587C206", title: "NATIONAL ARCHIVES OF ZIMBABWE ACT [CHAPTER 25:06]" },
    { guid: "FA96B08E-ABB3-4F3D-8693-E0949A6376FE", title: "NATIONAL ARTS COUNCIL OF ZIMBABWE ACT [CHAPTER 25:07]" },
    { guid: "D527011F-6AA2-4EE6-B77B-BD3F9A960DD2", title: "NATIONAL BIOTECHNOLOGY AUTHORITY ACT [CHAPTER 14:31]" },
    { guid: "92B06885-CB7C-4B3C-A604-86A7E17D561F", title: "NATIONAL COMPETITIVENESS COMMISSION ACT [CHAPTER 14:36]" },
    { guid: "CD96EB0E-ADD5-40BB-B1AE-8E59708265B7", title: "NATIONAL COUNCIL FOR HIGHER EDUCATION ACT [CHAPTER 25:08]" },
    { guid: "2FA714C4-1CA3-4E4F-B547-9FB7E9E0F841", title: "NATIONAL GALLERY OF ZIMBABWE ACT [CHAPTER 25:09]" },
    { guid: "45D511FF-F408-4BC8-AE77-3F9D2DDBE0B4", title: "NATIONAL HEROES ACT [CHAPTER 10:16]" },
    { guid: "575FC7E6-08FB-49A9-A72D-EDFC6D93E343", title: "NATIONAL INCOMES AND PRICING COMMISSION ACT [CHAPTER 14:32]" },
    { guid: "78993821-070F-4045-B77A-CE9FBC70D5EE", title: "NATIONAL LIBRARY AND DOCUMENTATION SERVICE ACT [CHAPTER 25:10]" },
    { guid: "2974854C-DFB1-42A5-A202-426BB33F7651", title: "NATIONAL MUSEUMS AND MONUMENTS ACT [CHAPTER 25:11]" },
    { guid: "5C0817F2-DD34-4B38-A492-EDD366312CEC", title: "NATIONAL PAYMENT SYSTEMS ACT [CHAPTER 24:23]" },
    { guid: "6DC77A3C-C7B2-41D5-B58C-B4B871FF378B", title: "NATIONAL PEACE AND RECONCILIATION COMMISSION ACT [CHAPTER 10:32]" },
    { guid: "366B813A-04CA-4432-921B-23BC78129B0F", title: "NATIONAL PROSECUTING AUTHORITY ACT [CHAPTER 7:20]" },
    { guid: "008164D1-ADA3-4954-BEB1-0B5CC520C77A", title: "NATIONAL PROSECUTING AUTHORITY ACT (ACT 05 of 2014)" },
    { guid: "A2A855D9-0485-4AE9-8FDE-4D0FDA3EBE28", title: "NATIONAL REGISTRATION ACT [CHAPTER 10:17]" },
    { guid: "88A3CB5B-711D-46B8-8FE2-AA80184918B6", title: "NATIONAL SERVICE ACT [CHAPTER 11:08]" },
    { guid: "1B7A6045-D80F-466C-9B93-AFE979AFE632", title: "NATIONAL SOCIAL SECURITY AUTHORITY ACT [CHAPTER 17:04]" },
    { guid: "B9FA3495-1244-4264-9389-9B92DF116ADD", title: "NATIONAL TRUST ACT [CHAPTER 25:12]" },
    { guid: "BA49BF14-C2CF-4AF5-9B6A-08A210ECF54C", title: "NATIONAL UNIVERSITY OF SCIENCE AND TECHNOLOGY ACT [CHAPTER 25:13]" },
    { guid: "254D7CDF-53BB-406A-88AD-E6C7F8B456E9", title: "NATURAL RESOURCES ACT [CHAPTER 20:13]" },
    { guid: "71F8F96E-BE36-4EED-B1E6-ECBF81895CF0", title: "NATURAL THERAPISTS ACT [CHAPTER 27:09]" },
    { guid: "94EA4D5E-61AB-4CC7-94B6-A2E95333BA04", title: "NETHERLANDS BANK OF RHODESIA LIMITED (PRIVATE) ACT [CHAPTER 24:08]" },
    { guid: "F2589038-F1E5-4665-91A3-133E3D7E1384", title: "NOXIOUS WEEDS ACT [CHAPTER 19:07]" },
    { guid: "4BCE37C2-FA0D-41D8-A6E5-29F2C880D287", title: "OFFICIAL SECRETS ACT [CHAPTER 11:09]" },
    { guid: "E4DE53A8-043D-475D-AA91-3267CDA50304", title: "OLDER PERSONS ACT [CHAPTER 17:11]" },
    { guid: "3B67C177-52DB-441F-8E3D-F83E035966D2", title: "ONE-STOP BORDER POSTS CONTROL ACT [CHAPTER 3:04]" },
    { guid: "9F4F36FE-742B-4085-A688-F4BBC60BB70C", title: "PAN-AFRICAN MINERALS UNIVERSITY OF SCIENCE AND TECHNOLOGY ACT [CHAPTER 25:33]" },
    { guid: "C9A3D5EF-0FAA-4799-8ABC-1041785D2678", title: "PARIRENYATWA HOSPITALS ACT [CHAPTER 15:07]" },
    { guid: "FF142E4C-1F82-43D2-A99D-E9E042A3B076", title: "PARKS AND WILD LIFE ACT [CHAPTER 20:14]" },
    { guid: "DBA61D65-D789-4D90-BCE9-EDADF0D4B268", title: "PARLIAMENTARY PENSIONS ACT [CHAPTER 2:02]" },
    { guid: "4E28DA29-FB35-4F06-AABD-5DF698BEFD77", title: "PARLIAMENTARY SALARIES, ALLOWANCES AND BENEFITS ACT [CHAPTER 2:03]" },
    { guid: "AAF9D225-10CD-40D3-97CB-443DDCA3234C", title: "PATENTS ACT [CHAPTER 26:03]" },
    { guid: "C5A596FA-58D2-45E4-A4EC-2F9402873441", title: "PAWNBROKERS ACT [CHAPTER 14:15]" },
    { guid: "1B3A36BD-8B13-4588-8F5F-145D96E36752", title: "PENSION AND PROVIDENT FUNDS ACT [CHAPTER 24:09]" },
    { guid: "6AEA3701-9E46-40C0-870C-C36CF26B6FCD", title: "PENSIONS (INCREASES AND ADJUSTMENTS) ACT [CHAPTER 16:02]" },
    { guid: "E70B7149-1364-4484-875C-EB1276C756BB", title: "PENSIONS AND OTHER BENEFITS ACT [CHAPTER 16:01]" },
    { guid: "DF662BBA-D24B-49A3-8930-2356BE6A3055", title: "PENSIONS REVIEW ACT [CHAPTER 16:03]" },
    { guid: "EF3B395B-FBFD-41C4-B09F-60FB21DFE78B", title: "PEOPLE’S OWN SAVINGS BANK OF ZIMBABWE ACT [CHAPTER 24:22]" },
    { guid: "14C4F187-409A-42B2-A5B9-74ECE10C69E4", title: "PETROLEUM ACT [CHAPTER 13:22]" },
    { guid: "B6DE2E79-52AD-4E36-9D22-4806A9B6EBE3", title: "PIG INDUSTRY ACT [CHAPTER 18:15]" },
    { guid: "7AFF8EF8-CCF3-4AFD-9CEA-D7AA13DFDBC2", title: "PIPELINES ACT [CHAPTER 13:08]" },
    { guid: "61A44B5F-5C41-4BAA-BBD0-52438833D30C", title: "PLANT BREEDERS RIGHTS ACT [CHAPTER 18:16]" },
    { guid: "AF63B7FA-7EF2-4B13-A8B8-CB6B1C6573D7", title: "PLANT PESTS AND DISEASES ACT [CHAPTER 19:08]" },
    { guid: "B209A3DA-32F8-417E-A4F4-F0F5745C86AB", title: "PNEUMONOCONIOSIS ACT [CHAPTER 15:08]" },
    { guid: "A1139A7B-5BDB-4D65-AFA8-6C03579286A0", title: "POLICE ACT [CHAPTER 11:10]" },
    { guid: "93D0B2F2-50A3-43BE-87AE-BE799C9FD622", title: "POLITICAL PARTIES (FINANCE) ACT [CHAPTER 2:04]" },
    { guid: "793EA4EF-F6F0-4BA3-A739-7591D06060FA", title: "POLITICAL PARTIES (FINANCE) ACT [CHAPTER 2:11]" },
    { guid: "F1C9A92D-D5F1-4016-A8CD-A2542BBFF7C8", title: "POOLS CONTROL ACT [CHAPTER 10:19]" },
    { guid: "E5035EA3-7A96-41BA-B8D0-0772E4B3C6E4", title: "POST OFFICE SAVINGS BANK ACT [CHAPTER 24:10]" },
    { guid: "E31177DF-EFB9-4CD8-B196-2CD0D1B391B8", title: "POSTAL AND TELECOMMUNICATION SERVICES ACT [CHAPTER 12:02]" },
    { guid: "AA9B8A0A-E1CF-4890-A3F2-054ECE792BEC", title: "POSTAL AND TELECOMMUNICATIONS ACT [CHAPTER 12:05]" },
    { guid: "17956A49-C928-412D-9ED5-C2DCA36309CD", title: "POSTS AND TELECOMMUNICATIONS CORPORATION ACT [CHAPTER 12:03]" },
    { guid: "5C53AE3C-2A8A-40EF-A26D-62BE0A39306E", title: "POWERS OF ATTORNEY ACT [CHAPTER 8:09]" },
    { guid: "DD80102B-621D-4803-9468-9FC2773CCD15", title: "PRECIOUS STONES TRADE ACT [CHAPTER 21:06]" },
    { guid: "39551E7C-85B4-4423-9032-9E0C488B92FA", title: "PRESCRIBED RATE OF INTEREST ACT [CHAPTER 8:10]" },
    { guid: "5C788598-FD00-4990-808D-97B880A462E6", title: "PRESCRIPTION ACT [CHAPTER 8:11]" },
    { guid: "559E6E2D-D66D-4B05-9ABB-28F47B74B5CE", title: "PRESERVATION OF CONSTITUTIONAL GOVERNMENT ACT [CHAPTER 11:11]" },
    { guid: "36504626-72E3-49C9-A0D8-3EA0E8ADAC75", title: "PRESIDENTIAL PENSION AND RETIREMENT BENEFITS ACT [CHAPTER 2:05]" },
    { guid: "D2D61267-EA69-4B9A-9A82-9D22FFFC81DF", title: "PRESIDENTIAL POWERS (TEMPORARY MEASURES) ACT [CHAPTER 10:20]" },
    { guid: "AEF82371-6103-45DB-949D-C5931BB139E1", title: "PRESIDENTIAL SALARY AND ALLOWANCES ACT [CHAPTER 2:06]" },
    { guid: "50B398F0-5595-44EA-95B2-C7E67980608C", title: "PREVENTION OF CORRUPTION ACT [CHAPTER 9:16]" },
    { guid: "AED7B589-1AF9-471E-80D4-49A2751FAAC0", title: "PREVENTION OF CRUELTY TO ANIMALS ACT [CHAPTER 19:09]" },
    { guid: "86A342DE-EEBE-4C34-BCD3-6CD08F02903C", title: "PREVENTION OF DISCRIMINATION ACT [CHAPTER 8:16]" },
    { guid: "654E9C7F-07EC-4580-8C3A-9A53010F409F", title: "PRINTED PUBLICATIONS ACT [CHAPTER 25:14]" },
    { guid: "D29A3C5A-611C-4026-BFCD-D57E0DFB880B", title: "PRISONS ACT [CHAPTER 7:11]" },
    { guid: "A8C7C466-5407-4513-ABC2-D36020003F1D", title: "PRIVATE BILL PROCEDURE ACT [CHAPTER 2:07]" },
    { guid: "C595E4A6-F024-4679-8320-6D28447FECF7", title: "PRIVATE BUSINESS CORPORATIONS ACT [CHAPTER 24:11]" },
    { guid: "BA5E1214-9510-4BEE-B6F0-9CE6C7284B3C", title: "PRIVATE INVESTIGATORS AND SECURITY GUARDS (CONTROL) ACT [CHAPTER 27:10]" },
    { guid: "618B89DA-C83F-4F71-B907-384DD762E643", title: "PRIVATE VOLUNTARY ORGANIZATIONS ACT [CHAPTER 17:05]" },
    { guid: "593DF870-8A3F-45E7-B2A6-954DF1CCB141", title: "PRIVILEGES AND IMMUNITIES ACT [CHAPTER 3:03]" },
    { guid: "FB61FF92-DE0E-49DB-A1BF-10DE1723D3F9", title: "PRIVILEGES, IMMUNITIES AND POWERS OF PARLIAMENT ACT [CHAPTER 2:08]" },
    { guid: "7067E14D-45E2-419D-8337-2CA1D6BF6667", title: "PROCUREMENT ACT [CHAPTER 22:14]" },
    { guid: "CD33F27F-2F16-414B-88B9-CF1E30DAC277", title: "PRODUCE EXPORT ACT [CHAPTER 18:17]" },
    { guid: "B738BA41-5D96-4C2E-B4A9-4FED0C59B2ED", title: "PROTECTED PLACES AND AREAS ACT [CHAPTER 11:12]" },
    { guid: "3C5BD5CC-ED76-4805-A46C-D8CA6245A189", title: "PROTECTION OF WILD LIFE (INDEMNITY) ACT [CHAPTER 20:15]" },
    { guid: "C040E5C0-AB77-4078-BA1E-7EA9D9A5AC1B", title: "PROVINCIAL COUNCILS AND ADMINISTRATION ACT [CHAPTER 29:11]" },
    { guid: "83A8BB1B-6875-4B43-82AC-4B025809F5BF", title: "PSYCHOLOGICAL PRACTICES ACT [CHAPTER 27:11]" },
    { guid: "BCED4841-0F55-48D6-9E03-76A7A25F4DEE", title: "PUBLIC ACCOUNTANTS AND AUDITORS ACT [CHAPTER 27:12]" },
    { guid: "95EA670A-7307-439F-A258-960AD596D89D", title: "PUBLIC BODIES PRIVATE BILL PROCEDURE ACT [CHAPTER 2:09]" },
    { guid: "46648274-B960-42FD-86AD-CDC519DAB645", title: "PUBLIC DEBT MANAGEMENT ACT [CHAPTER 22:21]" },
    { guid: "A19A472F-E3BB-41BD-9249-E5D4E4065A9C", title: "PUBLIC ENTITIES CORPORATE GOVERNANCE ACT [CHAPTER 10:33]" },
    { guid: "6A095931-E84C-42AF-8041-63052A22D761", title: "PUBLIC FINANCE MANAGEMENT ACT [CHAPTER 22:19]" },
    { guid: "06EC7A2D-BB51-49CC-8CD0-0A0A2A930C4F", title: "PUBLIC HEALTH ACT [CHAPTER 15:17]" },
    { guid: "41B2FBE3-9951-4F77-9307-4DCFD4508A0E", title: "PUBLIC HEALTH ACT [CHAPTER 15:09]" },
    { guid: "88144C57-91E1-42D3-9030-A0739AF32A87", title: "PUBLIC HOLIDAYS AND PROHIBITION OF BUSINESS ACT [CHAPTER 10:21]" },
    { guid: "91AA7D21-4F5C-402D-850C-24849FED613D", title: "PUBLIC ORDER AND SECURITY ACT [CHAPTER 11:17]" },
    { guid: "3035053E-D765-4C6B-BAB1-289B1D698AE1", title: "PUBLIC PREMISES (PREVENTION OF RACIAL DISCRIMINATION) ACT [CHAPTER 8:12]" },
    { guid: "55090A2F-14C9-4442-849F-21C3F3150631", title: "PUBLIC PROCUREMENT AND DISPOSAL OF PUBLIC ASSETS ACT [CHAPTER 22:23]" },
    { guid: "1779B9A8-97E4-4C16-893A-0F0C1B6EB280", title: "PUBLIC PROTECTOR ACT [CHAPTER 10:18]" },
    { guid: "8BA00B45-A1B9-400F-ADCB-4A1A805D4ECA", title: "QUANTITY SURVEYORS ACT [CHAPTER 27:13]" },
    { guid: "A7D774BC-EFA9-440E-BA4B-DCF06E69B9E6", title: "QUELEA CONTROL ACT [CHAPTER 19:10]" },
    { guid: "27AF9F51-95A8-4BC3-AAE1-3B886E6D57EA", title: "RADIATION PROTECTION ACT [CHAPTER 15:15]" },
    { guid: "E8A4B18E-CF09-4984-81DD-A6268D4C287B", title: "RADIOCOMMUNICATION SERVICES ACT [CHAPTER 12:04]" },
    { guid: "F42DDC81-3C62-42A9-A947-32878F5BA225", title: "RAILWAYS ACT [CHAPTER 13:09]" },
    { guid: "910B7F37-F578-4528-BE95-3FECB7D8DE14", title: "RECONSTRUCTION OF STATE-INDEBTED INSOLVENT COMPANIES ACT [CHAPTER 24:27]" },
    { guid: "EAECFE48-4AD5-437F-953C-BE0282DC1C86", title: "REFERENDUMS ACT [CHAPTER 2:10]" },
    { guid: "3FBD3820-DCF1-402A-AA44-5FEDE5E9E951", title: "REFUGEES ACT [CHAPTER 4:03]" },
    { guid: "2AD12285-099A-4801-9572-EB6FE75C92FB", title: "REGIONAL TOWN AND COUNTRY PLANNING ACT [CHAPTER 29:12]" },
    { guid: "FE1B301D-6B01-4541-B00A-DCAAA9DDBC0C", title: "REGIONAL WATER AUTHORITY ACT [CHAPTER 20:16]" },
    { guid: "48B86C48-DF75-49A7-847E-C877999EA857", title: "REGISTRATION OF PEDIGREE FARM LIVESTOCK ACT [CHAPTER 19:11]" },
    { guid: "F14544BB-AADF-4D37-8290-0A19F7D4C6C5", title: "RESEARCH ACT [CHAPTER 10:22]" },
    { guid: "C85114C4-40D8-4C7D-9C55-626C7A21678F", title: "RESERVE BANK OF ZIMBABWE (DEBT ASSUMPTION) ACT (ACT 02 of 2015)" },
    { guid: "FF401214-51D5-449B-A635-D5124E76D2DF", title: "RESERVE BANK OF ZIMBABWE ACT [CHAPTER 22:15]" },
    { guid: "8A6B0041-4A9A-4819-94BE-6908B8C5DEA0", title: "RESERVE BANK OF ZIMBABWE ACT [CHAPTER 22:10]" },
    { guid: "4DD30A67-5EFA-46A1-8C53-47FF1551283A", title: "RESERVED PARKING AREAS ACT [CHAPTER 10:23]" },
    { guid: "4D415109-55AA-4226-9316-B3AA52332F5A", title: "REVENUE AUTHORITY ACT [CHAPTER 23:11]" },
    { guid: "919B3131-5F24-4B72-96C8-4D95B34A600B", title: "RHODES ESTATES ACT [CHAPTER 20:17]" },
    { guid: "9736EFAA-8D5C-4245-99FD-BF8F9B033709", title: "RHODESIA AND MALAWI COMMON SERVICES GUARANTEE ACT [CHAPTER 22:11]" },
    { guid: "AFEF07B7-A058-4F3C-BC8F-A4FD90F83E09", title: "ROAD MOTOR TRANSPORTATION ACT [CHAPTER 13:10]" },
    { guid: "AC94D7AA-F236-4905-BF37-869607744257", title: "ROAD MOTOR TRANSPORTATION ACT [CHAPTER 13:15]" },
    { guid: "3FE9A721-5002-4635-99EF-ADE9D49091F0", title: "ROAD TRAFFIC ACT [CHAPTER 13:11]" },
    { guid: "D2EC01EF-040C-4115-AC20-0B3F73B33D21", title: "ROADS ACT [CHAPTER 13:12]" },
    { guid: "04A1A0B2-D31A-4F8C-A11F-E1328E711806", title: "ROADS ACT [CHAPTER 13:18]" },
    { guid: "CE5D41D7-D649-4B7B-96F2-76B8A2E78235", title: "ROASTING PLANT CORPORATION ACT [CHAPTER 21:07]" },
    { guid: "F1861191-7032-4752-BA1D-B17CAB56F979", title: "RURAL DISTRICT COUNCILS ACT [CHAPTER 29:13]" },
    { guid: "4B5E2873-62BE-4F33-86D3-3989950E74A9", title: "RURAL ELECTRIFICATION FUND ACT [CHAPTER 13:20]" },
    { guid: "66A78C6E-5529-4A00-8C82-C54B0E9FB209", title: "RURAL LAND ACT [CHAPTER 20:18]" },
    { guid: "AD699EC8-3001-4B8E-8733-C0C6C62E431C", title: "RURAL LAND OCCUPIERS (PROTECTION FROM EVICTION) ACT [CHAPTER 20:26]" },
    { guid: "262C18FC-31E7-4B58-AEB5-6FA25711F95C", title: "SALES TAX ACT [CHAPTER 23:08]" },
    { guid: "B21BED79-71B0-49BC-AC1C-9BCC88A9176C", title: "SAVINGS CERTIFICATES ACT [CHAPTER 22:12]" },
    { guid: "E1937E1B-56BC-42AE-ADFF-4B01BD91E054", title: "SCIENTIFIC EXPERIMENTS ON ANIMALS ACT [CHAPTER 19:12]" },
    { guid: "9217C59B-E519-4A43-91E1-55B8ABE2F133", title: "SECOND-HAND GOODS ACT [CHAPTER 14:16]" },
    { guid: "D98463B7-F52D-4F7D-A826-79CCD834C885", title: "SECURITIES AND EXCHANGE ACT [CHAPTER 24:25]" },
    { guid: "18FB5B74-58A2-4677-9656-197E6C2C16B0", title: "SEEDS ACT [CHAPTER 19:13]" },
    { guid: "C40BFD0A-04A9-433D-B7CF-580284ECE658", title: "SERICULTURE ACT [CHAPTER 18:18]" },
    { guid: "B72C9B7E-7511-47F9-AC60-B3A02789EE61", title: "SERIOUS OFFENCES (CONFISCATION OF PROFITS) ACT [CHAPTER 9:17]" },
    { guid: "D53C6536-6ED9-4634-8376-15C24BD45206", title: "SERVICE OF DOCUMENTS (TELEGRAPH) ACT [CHAPTER 8:13]" },
    { guid: "EF717C11-AF5E-4DBF-9AA2-CB31F346E70E", title: "SETTLED ESTATES LEASING ACT [CHAPTER 20:19]" },
    { guid: "EE27895F-36D1-499C-A7FF-66847F936EF2", title: "SEXUAL OFFENCES ACT [CHAPTER 9:21]" },
    { guid: "8F7B69DB-CF9B-4BE1-AFBD-E8862C8F0FFC", title: "SHOP LICENCES ACT [CHAPTER 14:17]" },
    { guid: "79191979-BCA6-48B7-AF18-A3A8CAC96279", title: "SIGNATURES AND POWERS DELEGATION ACT [CHAPTER 10:24]" },
    { guid: "4DC25EF5-520C-42C7-9DAC-076B7352A6B6", title: "SMALL AND MEDIUM ENTERPRISES ACT [CHAPTER 24:12]" },
    { guid: "150190C9-8678-4CCD-A4B0-768D0F82CDB3", title: "SMALL CLAIMS COURTS ACT [CHAPTER 7:12]" },
    { guid: "9C71D3E6-FA6E-47F4-B384-52485DF1CDC4", title: "SOCIAL WELFARE ASSISTANCE ACT [CHAPTER 17:06]" },
    { guid: "FFBA68A6-185C-43E3-AFAB-E4A15C1722DE", title: "SOCIAL WORKERS ACT [CHAPTER 27:21]" },
    { guid: "72030597-A16E-4ACA-AC59-358BCA7B2459", title: "SOUTH AFRICAN NATIONAL LIFE ASSURANCE COMPANY (PRIVATE) ACT [CHAPTER 24:13]" },
    { guid: "FBE0BFA7-E3CC-44D8-B88D-3CFBC0B83861", title: "SOVEREIGN WEALTH FUND OF ZIMBABWE ACT [CHAPTER 22:20]" },
    { guid: "0A59EC34-CD01-49FB-8947-39779A1734E4", title: "SPECIAL ECONOMIC ZONES ACT [CHAPTER 14:34]" },
    { guid: "BB2896EF-F7B9-48CA-A234-90398CE8C71E", title: "SPORTS AND RECREATION COMMISSION ACT [CHAPTER 25:15]" },
    { guid: "9BA603FB-D72B-499F-9DB8-9BDDDB89A06F", title: "STAMP DUTIES ACT [CHAPTER 23:09]" },
    { guid: "1522E8B6-7C5D-4BBD-8FC6-9A6DA52DF368", title: "STANDARDIZATION OF SOAP ACT [CHAPTER 14:18]" },
    { guid: "4A3317AE-C288-43A9-A0D0-7C562F39015A", title: "STANDARDS DEVELOPMENT FUND ACT [CHAPTER 14:19]" },
    { guid: "7F13F8E4-10D4-44FD-A371-D37F669C0680", title: "STATE LIABILITIES ACT [CHAPTER 8:14]" },
    { guid: "30532217-CBE2-49F8-B2AC-9EB6A1DFE30F", title: "STATE LOANS AND GUARANTEES ACT [CHAPTER 22:13]" },
    { guid: "26AE743F-CE5C-4176-BE28-C491735DC621", title: "STATE LOTTERIES ACT [CHAPTER 17:07]" },
    { guid: "896EDF6D-7093-44C0-8534-E70E9F00589B", title: "STATE SERVICE (DISABILITY BENEFITS) ACT [CHAPTER 16:05]" },
    { guid: "20B0F99F-9A47-44FD-8A77-745B3E435A1E", title: "STATE SERVICE (PENSIONS) ACT [CHAPTER 16:06]" },
    { guid: "145A6A64-877B-4674-A32D-637A76B09DF8", title: "STATUTE LAW COMPILATION AND REVISION ACT [CHAPTER 1:03]" },
    { guid: "7F810C8A-28A6-4F72-81B8-D3B111C89771", title: "STOCK THEFT PREVENTION ACT [CHAPTER 9:18]" },
    { guid: "DCE9E550-EB12-49D6-9A4A-CDD466885C29", title: "STOCK TRESPASS ACT [CHAPTER 19:14]" },
    { guid: "79FE88F6-422A-4DA1-989D-9919657D33A7", title: "SUGAR PRODUCTION CONTROL ACT [CHAPTER 18:19]" },
    { guid: "7420ABC3-C410-4D73-A29A-7E2A2CEB259B", title: "SUPPRESSION OF FOREIGN AND INTERNATIONAL TERRORISM ACT [CHAPTER 11:21]" },
    { guid: "075A105C-2D0C-4464-A549-41EA1C3AAB8F", title: "SUPREME COURT ACT [CHAPTER 7:13]" },
    { guid: "EE99FB59-25A5-4557-B95B-35FC7E815BCC", title: "TACIT HYPOTHEC AMENDMENT ACT [CHAPTER 6:05]" },
    { guid: "2649F86B-C5C6-44E3-B375-639ECE9F4DEF", title: "TARIFF COMMISSION ACT [CHAPTER 14:29]" },
    { guid: "6731B1CB-5AE5-4338-95C8-697081FE31CF", title: "TAX RESERVE CERTIFICATES ACT [CHAPTER 23:10]" },
    { guid: "A84B7A43-1CC2-4D6F-8A9A-954E04959FA1", title: "TERMINATION OF PREGNANCY ACT [CHAPTER 15:10]" },
    { guid: "1BB3B542-9C23-4F61-9663-6573798FD353", title: "TITLES REGISTRATION AND DERELICT LANDS ACT [CHAPTER 20:20]" },
    { guid: "C6D74F39-B385-48FB-837A-8DD7E3E99A08", title: "TOBACCO INDUSTRY AND MARKETING ACT [CHAPTER 18:20]" },
    { guid: "88AF554B-3158-4A8C-91A4-E39A71E69A26", title: "TOBACCO RESEARCH ACT [CHAPTER 18:21]" },
    { guid: "334E1676-D9F6-4EED-9F7B-7ED95933F97B", title: "TOLL-ROADS ACT [CHAPTER 13:13]" },
    { guid: "18EC3F2D-4B3B-4A4E-9CB1-9A7A16C05F46", title: "TOURISM ACT [CHAPTER 14:20]" },
    { guid: "B8148CBA-EF91-4C88-95C3-9806CE8D791E", title: "TRADE COUPONS ACT [CHAPTER 14:21]" },
    { guid: "CD850143-8868-4CA4-A080-76D73DDA18C6", title: "TRADE DEVELOPMENT SURCHARGE ACT [CHAPTER 14:22]" },
    { guid: "5D86B7C7-5C33-4E91-8521-9A1115FD0AEF", title: "TRADE MARKS ACT [CHAPTER 26:04]" },
    { guid: "0D73224F-E228-4303-A7B9-92FF3D2C2F17", title: "TRADE MEASURES ACT [CHAPTER 14:23]" },
    { guid: "4DE0B405-E463-47B5-8EBE-31E9AA22AAC7", title: "TRADITIONAL BEER ACT [CHAPTER 14:24]" },
    { guid: "48C64907-8677-4BA2-A3AC-3063C0EFBB5E", title: "TRADITIONAL LEADERS ACT [CHAPTER 29:17]" },
    { guid: "8C11D46C-401D-4C76-9562-BA6AF6974677", title: "TRADITIONAL MEDICAL PRACTITIONERS ACT [CHAPTER 27:14]" },
    { guid: "DE901022-BBCE-4C66-99B4-6A5738F16CC0", title: "TRAFFIC SAFETY COUNCIL ACT [CHAPTER 13:17]" },
    { guid: "855E02C0-7AC8-40F4-94E4-40330815EDB7", title: "TRAFFICKING IN PERSONS ACT [CHAPTER 9:25]" },
    { guid: "8BA7DFB4-E93F-400C-B922-439A9DA9D04D", title: "TRANSFER OF OFFENDERS ACT [CHAPTER 7:14]" },
    { guid: "08F5BCD6-ADEC-41C2-9BE4-A1C4AEC6D511", title: "TRAPPING OF ANIMALS (CONTROL) ACT [CHAPTER 20:21]" },
    { guid: "7E3C1A37-A69A-4D8E-9AF9-35DD57E538E5", title: "TROUBLED FINANCIAL INSTITUTIONS (RESOLUTION) ACT [CHAPTER 24:28]" },
    { guid: "FD0AD023-1270-42AB-82F4-4BDCCE57B3D9", title: "UNIVERSITY OF ZIMBABWE ACT [CHAPTER 25:16]" },
    { guid: "BE693D2F-23A1-4819-BFA9-70A933C82EB1", title: "UNLAWFUL ORGANIZATIONS ACT [CHAPTER 11:13]" },
    { guid: "C50FB87B-6E56-4D1F-9E9C-F85AA48738D3", title: "URBAN AREAS (OMNIBUS SERVICES) ACT [CHAPTER 29:14]" },
    { guid: "AE5D06AE-6305-49B7-A595-260D3D35473F", title: "URBAN COUNCILS ACT [CHAPTER 29:15]" },
    { guid: "57ED876E-9BCA-4C78-8DDD-2F4B9235172B", title: "URBAN DEVELOPMENT CORPORATION ACT [CHAPTER 29:16]" },
    { guid: "2EEE51BC-EB4C-451D-B7E2-268EFA529AFD", title: "VAGRANCY ACT [CHAPTER 10:25]" },
    { guid: "4AA9375D-56C8-4391-A5FC-9DC1667D1D00", title: "VALUE ADDED TAX ACT [CHAPTER 23:12]" },
    { guid: "B695991D-2279-49DD-A945-AEF79DD72A24", title: "VALUERS ACT [CHAPTER 27:18]" },
    { guid: "943F03CE-9600-424A-8DF9-E22E6F46C7AE", title: "VARIATION OF RACIAL TRUSTS ACT [CHAPTER 8:15]" },
    { guid: "709BA4C4-B5C6-4C0E-B363-F4B150C0A4E5", title: "VEHICLE REGISTRATION AND LICENSING ACT [CHAPTER 13:14]" },
    { guid: "d190ea96-0dfa-4504-b14e-f9710b26974b", title: "VETERANS OF THE LIBERATION STRUGGLE ACT [CHAPTER 17:12]" },
    { guid: "27D93173-C18A-4327-ADED-3C584CDA5E80", title: "VETERINARY SURGEONS ACT [CHAPTER 27:15]" },
    { guid: "0A424D33-DC64-45CE-92DD-9BB090D5F74C", title: "WAR MARRIAGES VALIDATION ACT [CHAPTER 5:15]" },
    { guid: "6D184502-079C-4FBE-A88E-B7DCF052CE84", title: "WAR PENSIONS ACT [CHAPTER 11:14]" },
    { guid: "9C2A9167-A45B-4E96-90E9-26898D2951A6", title: "WAR VETERANS ACT [CHAPTER 11:15]" },
    { guid: "4048809A-FB42-482D-8230-77256AF1D469", title: "WAR VICTIMS COMPENSATION ACT [CHAPTER 11:16]" },
    { guid: "3DCCE124-12BA-4D2A-8407-CC91C28753C4", title: "WAREHOUSE RECEIPT ACT [CHAPTER 18:25]" },
    { guid: "236C2306-2F93-4847-9796-DDD14EF522EE", title: "WATER ACT [CHAPTER 20:22]" },
    { guid: "83D21F78-845B-4714-9D83-5C24B15C0645", title: "WATER ACT [CHAPTER 20:24]" },
    { guid: "4181DB0C-3133-4C40-8E6A-80B191F57435", title: "WHITE PHOSPHORUS MATCHES ACT [CHAPTER 14:25]" },
    { guid: "F05AD42C-E53B-4724-9CB6-321E31B100AF", title: "WILLS ACT [CHAPTER 6:06]" },
    { guid: "3F643DF4-3678-4CD2-8430-D662712A2EC7", title: "WITCHCRAFT SUPPRESSION ACT [CHAPTER 9:19]" },
    { guid: "6812EF66-4234-4788-ABC1-84AA71D049D4", title: "ZAMBEZI RIVER AUTHORITY ACT [CHAPTER 20:23]" },
    { guid: "073B8701-40AA-477D-9004-9AB56D81B6A8", title: "ZEP-RE (MEMBERSHIP OF ZIMBABWE AND BRANCH OFFICE AGREEMENT) ACT (ACT 04 of 2017)" },
    { guid: "937304FB-6D98-4144-981A-10E1C10F27AF", title: "ZIMBABWE BROADCASTING CORPORATION (COMMERCIALISATION) ACT (ACT 26 of 2001)" },
    { guid: "81D519B1-93E9-4A34-850F-566B3A8C095D", title: "ZIMBABWE BROADCASTING CORPORATION (DEBT ASSUMPTION) ACT (ACT 11 of 2004)" },
    { guid: "2DEEFF58-738B-4ACC-B4C2-A2E0FCCDBA7D", title: "ZIMBABWE BROADCASTING CORPORATION ACT [CHAPTER 12:01]" },
    { guid: "FBFE7B6D-39AD-4B36-A2C9-048912ACD84B", title: "ZIMBABWE COUNCIL FOR HIGHER EDUCATION ACT [CHAPTER 25:27]" },
    { guid: "DD946DF9-BFDA-41E9-85C1-1EC6989BDC11", title: "ZIMBABWE DEVELOPMENT CORPORATION ACT [CHAPTER 24:15]" },
    { guid: "A0E02573-3BF7-4ECA-9F0F-F0F80CAB9A10", title: "ZIMBABWE ELECTORAL COMMISSION ACT [CHAPTER 2:12]" },
    { guid: "02B53F4C-59D3-4695-8E70-516B23267C3B", title: "ZIMBABWE GENDER COMMISSION ACT [CHAPTER 10:31]" },
    { guid: "AE8D58B4-90DE-4DB7-B3CB-0D23EB269EF8", title: "ZIMBABWE HUMAN RIGHTS COMMISSION ACT [CHAPTER 10:30]" },
    { guid: "1C13DB09-306C-4A4A-A98F-3435D0D80FFA", title: "ZIMBABWE INSTITUTE OF PUBLIC ADMINISTRATION AND MANAGEMENT ACT [CHAPTER 25:17]" },
    { guid: "CDE0F7C9-729C-4499-BEB7-EFF5AAC158B3", title: "ZIMBABWE INSTITUTION OF ENGINEERS (PRIVATE) ACT [CHAPTER 27:16]" },
    { guid: "81EC1DD2-60E2-441B-81E4-B9D10E66B6EA", title: "ZIMBABWE INTERNATIONAL TRADE FAIR ACT [CHAPTER 14:26]" },
    { guid: "71AE4C5E-0111-470A-891A-622EB0A50077", title: "ZIMBABWE INVESTMENT AND DEVELOPMENT AGENCY ACT [CHAPTER 14:38]" },
    { guid: "1CC96D13-D0CF-4B6C-A172-96CA8DA43200", title: "ZIMBABWE INVESTMENT AUTHORITY ACT [CHAPTER 14:30]" },
    { guid: "DBFE5A08-1056-4DBD-BA7E-2DDE7128D0E5", title: "ZIMBABWE INVESTMENT CENTRE ACT [CHAPTER 24:16]" },
    { guid: "A059485A-6E7C-4408-8B41-8001615542C3", title: "ZIMBABWE IRON AND STEEL COMPANY (DEBT ASSUMPTION) ACT (ACT 05 of 2018)" },
    { guid: "719b029e-0780-423f-9cb0-0cb87d849498", title: "ZIMBABWE MEDIA COMMISSION ACT [CHAPTER 10:35]" },
    { guid: "030FB734-DA3A-424B-BA1F-21894EAD9EA8", title: "ZIMBABWE MINING DEVELOPMENT CORPORATION ACT [CHAPTER 21:08]" },
    { guid: "4D6C97E4-7D80-4708-A6A9-7797FD44CA31", title: "ZIMBABWE NATIONAL FAMILY PLANNING COUNCIL ACT [CHAPTER 15:11]" },
    { guid: "08FE61D9-FB71-4B3E-A5EC-F5C013BA2F61", title: "ZIMBABWE NATIONAL SECURITY COUNCIL ACT [CHAPTER 11:22]" },
    { guid: "3396E6A7-1616-4EB8-8344-7CA847248A2A", title: "ZIMBABWE NATIONAL WATER AUTHORITY ACT [CHAPTER 20:25]" },
    { guid: "36AC9A59-43AE-4DCC-AE45-B7D47412D766", title: "ZIMBABWE OPEN UNIVERSITY ACT [CHAPTER 25:20]" },
    { guid: "126C1F9F-DC3F-4803-A3EB-89ADA4DBF374", title: "ZIMBABWE RED CROSS SOCIETY ACT [CHAPTER 17:08]" },
    { guid: "D018C2B8-3555-4F0C-9B98-6E4E8316AF40", title: "ZIMBABWE REINSURANCE CORPORATION ACT [CHAPTER 24:17]" },
    { guid: "ACB04A97-E2CB-4576-990C-B4B4F13BCB8D", title: "ZIMBABWE SCHOOL EXAMINATIONS COUNCIL ACT [CHAPTER 25:18]" },
    { guid: "32999F0A-6004-41ED-B0F6-641F867BB4B2", title: "ZIMBABWE STATE TRADING CORPORATION ACT [CHAPTER 14:27]" },
    { guid: "85774323-3565-461A-955E-3E65B4CF282D", title: "ZIMBABWE STOCK EXCHANGE ACT [CHAPTER 24:18]" },
    { guid: "F1B1BEAA-2442-4DB6-9856-CA93CEEC76FB", title: "ZIMBABWE YOUTH AND SPORTS AND RECREATION COUNCILS ACT [CHAPTER 25:19]" }
];

export default acts;
