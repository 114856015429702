import React from 'react'
import { SearchResult } from '../../AppStore/Slices/UISlice';

interface ISearchResultItemProps {
    item: SearchResult;
    onClick: (sourceId: string, documentId: string) => void;
    selected: boolean;
    sourceTitle: string;
    sourceImageUrl: string;
};

const SearchResultItem = ({ item, onClick, selected, sourceTitle, sourceImageUrl }: ISearchResultItemProps) => {
    const handleClick = (event: React.MouseEvent<HTMLLIElement>): void => {
      onClick(item.sourceGuid, item.documentGuid);
    }

    const classNames = 'search-result-item flex-across' + (selected ? ' selected' : '');

    return (
      <li id={item.documentGuid} className={classNames} onClick={handleClick}>
        <div className="p-1">
          <img src={sourceImageUrl} alt={sourceImageUrl}/>
        </div>
        <div className="pl-1">
          <ul>
            <li>{sourceTitle}<span className="text-secondary">&nbsp;(Rank: {item.rank.toFixed(2)})</span></li>
            <li>{item.documentTitle}</li>
            <li>{item.title}</li>
          </ul>
        </div>
      </li>);
}

export default SearchResultItem;
